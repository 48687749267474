<!-- eslint-disable max-len -->

<template>
  <section>
    <Navbar />
    <section class="profile">
      <section class="hero is-small">
        <div class="responsive-pad">
          <h1 class="admin-title">{{ $t('user.title-thin') }} </h1> <h1 class="admin-title fat-text"> {{ $t('user.title-bold') }}</h1>
        </div>
      </section>
      <b-tabs v-model="activeTab" class="tab tab-custom responsive-pad">
        <b-tab-item :label="$t('user.search')">
          <div class="">
            <b-field :label="$t('user.search-title')">
              <b-autocomplete
                v-model="name"
                :placeholder="$t('user.search-placeholder')"
                :keep-first="keepFirst"
                :open-on-focus="openOnFocus"
                :data="user_list"
                field="fullQName"
                @select="load($event)"
                :clearable="clearable"
                @input="search(name)">
              </b-autocomplete>
            </b-field>
            <b-table
            class="table"
            :data="user_data.courses"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage2"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :default-sort-direction="defaultSortDirection"
            :sort-icon="sortIcon"
            :sort-icon-size="sortIconSize"
            hoverable
            default-sort="lastname"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page">
              <b-table-column field="name" :label="$t('common.training')" sortable
              v-slot="props">
                {{ props.row.name }}
              </b-table-column>
              <b-table-column field="curr_unit" :label="$t('user.progress')" sortable
              v-slot="props">
                {{ props.row.curr_unit + 1 }}
              </b-table-column>
              <b-table-column field="created" :label="$t('user.assigned-on')" v-slot="props" sortable>
                {{ dateFormat(props.row.created) }}
              </b-table-column>
              <b-table-column field="settings.last_edit" :label="$t('user.last-edit')"
              v-slot="props" sortable>
                {{ dateFormat(props.row.settings.last_edit) }}
              </b-table-column>
              <b-table-column field="settings.last_send" :label="$t('user.last-mail')"
              v-slot="props" sortable>
                {{ dateFormat(props.row.settings.last_send) }}
              </b-table-column>
              <b-table-column field="status" :label="$t('user.status')" v-slot="props" sortable>
                {{ status(props.row.status, props.row.settings.frequency) }}
              </b-table-column>
              <b-table-column :label="$t('user.actions')" v-slot="props">
                <b-dropdown aria-role="list">
                  <template #trigger="">
                    <span class="icon-frame">
                      <font-awesome-icon :icon="['fa', 'ellipsis-h']" />
                    </span>
                  </template>
                  <b-dropdown-item class="options-dropdown-delete"
                  @click="del_course({id: props.row.id, status: 'delete'})">
                    <font-awesome-icon :icon="['fa', 'trash']" />
                    {{ $t('common.delete') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                  v-if="status(props.row.status, props.row.settings.frequency) === 'pausiert'"
                  @click="toggle_status({id: props.row.id, status: 'continue'})">
                    <font-awesome-icon :icon="['fa', 'play']" />
                    {{ $t('user.continue') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="status(props.row.status, props.row.settings.frequency) === 'aktiv'"
                    @click="toggle_status({id: props.row.id, status: 'pause'})">
                    <font-awesome-icon :icon="['fa', 'pause']" />
                    {{ $t('user.pause') }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-table-column>
            </b-table>
            <Button :type="'zoom'" :text="$t('user.del-user')" :disabled="!user_data.user_id"
            @click="del_user(user_data.user_id)"/>
          </div>
        </b-tab-item>

        <b-tab-item v-if="mail_issues.length != 0" :label="$t('user.mail-issues')">
          <b-table
          class="table"
          :data="mail_issues"
          :paginated="isPaginated"
          :per-page="perPage"
          :current-page.sync="currentPage2"
          :pagination-simple="isPaginationSimple"
          :pagination-position="paginationPosition"
          :default-sort-direction="defaultSortDirection"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          default-sort="lastname"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page">
            <b-table-column field="name" :label="$t('user.name')" sortable
            v-slot="props">
              {{ props.row.fullName }}
            </b-table-column>
            <b-table-column field="u" :label="$t('user.email')" sortable
            v-slot="props">
              {{ props.row.u }}
            </b-table-column>
            <b-table-column field="mailinfo.status" :label="$t('user.mail-status')" v-slot="props" sortable>
              {{ props.row.mailinfo.status }}
            </b-table-column>
            <b-table-column field="mailinfo.status" :label="$t('user.occurrence')"
            v-slot="props" sortable>
              {{ US_to_EU_date(props.row.mailinfo.timestamp) }}
            </b-table-column>
            <b-table-column :label="$t('user.actions')" v-slot="props">
              <b-dropdown aria-role="list">
                <template #trigger="">
                  <span class="icon-frame">
                    <font-awesome-icon :icon="['fa', 'ellipsis-h']" />
                  </span>
                </template>
                <b-dropdown-item
                @click="toggle_mail_modal(props.row)">
                  <font-awesome-icon :icon="['fa', 'info-circle']" />
                  {{ $t('user.details') }}
                </b-dropdown-item>
                <b-dropdown-item class="options-dropdown-delete"
                @click="del_user(props.row.id)">
                  <font-awesome-icon :icon="['fa', 'trash']" />
                  {{ $t('user.del-user') }}
                </b-dropdown-item>
                <b-dropdown-item class="options-dropdown-delete"
                   @click="del_error(props.row.id)">
                  <font-awesome-icon :icon="['fa', 'trash']" />
                  {{ $t('user.del-notification') }}
                </b-dropdown-item>
              </b-dropdown>
              <div v-if="mailModal" class="modal is-active">
                <div class="modal-background" @click="toggle_mail_modal()"></div>
                <div class="modal-card">
                  <header class="modal-card-head">
                    <p class="modal-card-title">{{ $t('user.details') }}</p>
                    <button class="delete" aria-label="close" @click="toggle_mail_modal()"></button>
                  </header>
                  <section class="modal-card-body">
                    <div class="field">
                      <h2>{{ $t('user.name') }}: </h2> <p>{{ mail_fullQName }}</p>
                    </div>
                    <div class="field">
                      <h2>{{ $t('user.email') }}: </h2> <p>{{ mail_u }}</p>
                    </div>
                    <div class="field">
                      <h2>{{ $t('user.status') }}: </h2> <p>{{ mailinfo.status }}</p>
                    </div>
                    <div class="field">
                      <h2>Error: </h2> <p>{{ mailinfo.error }}</p>
                    </div>
                    <div v-if="mailinfo.comment" class="field">
                      <h2>Comment: </h2> <p>{{ mailinfo.comment }}</p>
                    </div>
                    <div class="field">
                      <h2>{{ $t('user.occurrence') }}: </h2><p>{{ US_to_EU_date(mailinfo.timestamp) }}</p>
                    </div>
                  </section>
                  <footer class="modal-card-foot">
                    <button class="button is-danger" @click="toggle_mail_modal()">{{ $t('common.close') }}</button>
                  </footer>
                </div>
              </div>
            </b-table-column>
          </b-table>
        </b-tab-item>
      </b-tabs>
    </section>
    <Footer />
  </section>
</template>

<script>
/* eslint-disable arrow-body-style */

import formatting from '@/traincom/mixins/formatting';
import Button from '@/traincom/components/Common/Button.vue';
import Navbar from '../../components/Common/Navbar.vue';
import Footer from '../../components/Common/Footer.vue';

export default {
  components: {
    Navbar,
    Footer,
    Button,
  },
  mixins: [formatting],
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      currentPage2: 1,
      perPage: 10,
      keepFirst: false,
      openOnFocus: false,
      name: '',
      selected: null,
      clearable: false,
      activeTab: 0,
      mailModal: false,
      mailinfo: {},
      mail_fullQName: '',
      mail_u: '',
    };
  },
  created() {
    this.$store.dispatch('userProcess/mail_issues');
  },
  computed: {
    user_list() {
      return this.$store.getters['userProcess/get_user_list'];
    },
    user_data() {
      return this.$store.getters['userProcess/get_selected_user_data'];
    },
    mail_issues() {
      return this.$store.getters['userProcess/get_mail_issues'];
    },
  },

  methods: {
    load(option) {
      console.log(option);
      this.selected = option;
      this.user_detail();
    },
    clear() {
      this.$store.commit('userProcess/clearSearch');
      this.$store.dispatch('userProcess/mail_issues');
      this.name = '';
    },
    toggle_status(payload) {
      this.$store.dispatch('userProcess/toggle_status', payload).then((response) => {
        if (response) {
          this.user_detail();
        }
      });
    },
    set_done(courseId) {
      this.$store.dispatch('userProcess/set_done', courseId).then((response) => {
        if (response) {
          this.user_detail();
        }
      });
    },
    toggle_mail_modal(user) {
      if (user) {
        this.mailinfo = user.mailinfo;
        this.mail_fullQName = user.fullQName;
        this.mail_u = user.u;
      }
      this.mailModal = !this.mailModal;
    },
    del_user(userId) {
      this.$buefy.dialog.confirm({
        title: this.$t('user.del-user'),
        message: this.$t('user.del-user-text'),
        cancelText: this.$t('common.cancel'),
        confirmText: this.$t('user.del-user'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch('userProcess/del_user', userId).then((response) => {
            if (response) {
              this.$buefy.toast.open({
                duration: 3000,
                message: this.$t('user.del-user-success'),
                type: 'is-success',
              });
              this.clear();
            }
          });
        },
      });
    },
    del_error(userId) {
      this.$buefy.dialog.confirm({
        title: this.$t('user.del-notification'),
        message: this.$t('user.del-notification-text'),
        cancelText: this.$t('common.cancel'),
        confirmText: this.$t('user.del-notification'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch('userProcess/del_mail_error', userId).then((response) => {
            if (response) {
              this.$buefy.toast.open({
                duration: 3000,
                message: this.$t('user.del-notification-success'),
                type: 'is-success',
              });
              this.clear();
            }
          });
        },
      });
    },
    del_course(payload) {
      this.$buefy.dialog.confirm({
        title: this.$t('user.del-training'),
        message: this.$t('user.del-training-text'),
        cancelText: this.$t('common.cancel'),
        confirmText: this.$t('user.del-training'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch('userProcess/toggle_status', payload).then((response) => {
            if (response) {
              this.user_detail();
              this.$buefy.toast.open({
                duration: 3000,
                message: this.$t('user.del-training-success'),
                type: 'is-danger',
              });
            }
          });
        },
      });
    },
    user_detail() {
      if (this.selected === null) return;
      this.$store.dispatch('userProcess/get_user_data', this.selected.id).then((response) => {
        if (response) {
          console.log('Trainings loaded');
        } else {
          this.$buefy.toast.open({
            duration: 3000,
            message: this.$t('user.no-data-found'),
            type: 'is-danger',
          });
        }
      });
    },
    search() {
      if (this.name === '') return;
      this.$store.dispatch('userProcess/search_user', this.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  font-family: "Roboto";
  margin-top: 3.899rem;

  .notification {
    background-color: white;
    padding-left: 2.5rem !important;
  }

  .hero-body {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .admin-title {
    display: inline;
    text-transform: uppercase;
    font-size: 3em;
    padding-top: 2rem;
  }

  .fat-text {
    font-family: "Roboto";
    font-weight: 900;
  }

  .background-color-client {
    background-color: var(--secondary-color);
    color: var(--secondary-color-text);
  }

  .icon-frame {
    padding: .2rem .5rem;
    cursor: pointer;
  }

  .icon {
    height: 1rem !important;
  }

  .options-dropdown {
    color: black !important;
  }

  .options-dropdown-delete {
    background-color: #f14668 !important;
    border-color: transparent !important;
    color: #fff !important;
    margin-bottom: .1rem;
  }
}
</style>
