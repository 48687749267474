<template>
  <section>
    <Navbar />
    <CourseMain />
    <CourseTabs />
    <Footer />
  </section>
</template>

<script>
/* eslint-disable no-restricted-globals */

import Navbar from '../../components/Common/Navbar.vue';
import Footer from '../../components/Common/Footer.vue';
import CourseMain from '../components/Course/CourseMain.vue';
import CourseTabs from '../components/Course/CourseTabs.vue';

export default {
  components: {
    Navbar,
    Footer,
    CourseMain,
    CourseTabs,
  },

  beforeRouteLeave(to, from, next) {
    this.$dialog.confirm('Wenn Sie das Training jetzt verlassen, kann es sein, dass ein Teil Ihres Fortschrittes verloren gehen wird.')
      .then(() => {
        next();
      })
      .catch(() => {
        next(false);
      });
  },
};
</script>

<style lang="scss" theme="a">
.dg-btn--ok {
  border-color: black;
  color: black;
  font-size: 1rem;
}

.dg-btn--cancel {
  background-color: var(--primary-color);
  color: var(--primary-color-text);
  font-size: 1rem;
}

.dg-content {
  font-family: "Roboto";
  color: black;
  font-weight: 400;
  font-size: 1.2rem;
}

.dg-btn-loader .dg-circle {
  background-color: var(--primary-color);
}
</style>

<style lang="scss" theme="b">
.dg-btn--ok {
  border-color: black;
  color: black;
  font-size: 1rem;
}

.dg-btn--cancel {
  background-color: var(--primary-color);
  color: var(--primary-color-text);
  font-size: 1rem;
}

.dg-content {
  color: black;
  font-weight: 400;
  font-size: 1.2rem;
}

.dg-btn-loader .dg-circle {
  background-color: var(--primary-color);
}
</style>
