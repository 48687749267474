<template>
<div>
  <b-loading :is-full-page="true"
  :can-cancel="true" />
  <Navbar />
  <section class="stats">
    <section class="hero background-color-client is-small">
      <div class="hero-body">
        <h1 class="stats-title">{{ $t('stats.title-thin') }}</h1>
        <h1 class="stats-title fat-text"> {{ $t('stats.title-bold') }}</h1>
        <h1 class="stats-subtitle">{{ curr_assignment.name }}</h1>
      </div>
    </section>
    <div v-if="!loading" class="columns is-centered is-multiline">
      <div class="column is-half" width="100%">
        <apexchart type="bar" :options="barOptions" :series="series1">
        </apexchart>
      </div>
      <div class="column is-half" width="100%">
        <apexchart type="pie" height="440px"
        :options="pieOptions" :series="series2">
        </apexchart>
      </div>
    </div>
    <b-table v-if="!loading"
      class="table"
      :data="assignment_courses"
      :paginated="isPaginated"
      :per-page="perPage"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :pagination-position="paginationPosition"
      :default-sort-direction="defaultSortDirection"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      default-sort="lastname"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page">

        <b-table-column searchable field="user_id.firstname" :label="$t('stats.prename')" sortable
        v-slot="props">
          {{ props.row.user_id.firstname }}
        </b-table-column>

        <b-table-column searchable field="user_id.lastname" :label="$t('stats.name')" sortable
        v-slot="props">
          {{ props.row.user_id.lastname }}
        </b-table-column>

        <b-table-column field="user_id.login" :label="$t('stats.password')" sortable
        v-slot="props">
          {{ props.row.user_id.login }}
        </b-table-column>

        <b-table-column v-if="!live" field="curr_unit" :label="$t('stats.at-unit')" sortable numeric
        v-slot="props">
          {{ props.row.curr_unit + 1 }}
        </b-table-column>

        <b-table-column v-if="live" :label="$t('stats.done')" sortable
        v-slot="props">
          <b-checkbox @input="changeDone(props)"
          :value="props.row.status === 12"></b-checkbox>
        </b-table-column>

        <b-table-column v-if="!live" field="curr_page" :label="$t('stats.on-page')"
        sortable numeric v-slot="props">
          {{ props.row.curr_page + 1 }}
        </b-table-column>
    </b-table>
    <Button @click="printStats()" :text="'Download PDF'" />
    <Button @click="get_csv()" :text="'Download CSV'" />
    <Footer />
  </section>
</div>
</template>

<script>
/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import Button from '@/traincom/components/Common/Button.vue';
import Navbar from '../../components/Common/Navbar.vue';
import Footer from '../../components/Common/Footer.vue';

export default {
  components: {
    Navbar,
    Footer,
    Button,
  },

  created() {
    this.$store.dispatch('Assignment/load', this.$route.params.assignment_id);
    this.$store.dispatch('Assignment/load_courses', this.$route.params.assignment_id).then(() => {
      this.$store.dispatch('Course/load', this.assignment_courses[0].id);
    });
  },

  data() {
    return {
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 10,
      pieOptions: {
        title: {
          text: this.$t('stats.total-parts'),
          margin: 30,
          align: 'left',
          style: {
            fontSize: '25px',
            margin: '100',
          },
        },
        labels: [this.$t('stats.not-started'), this.$t('stats.in-progress'), this.$t('stats.in-anchor'), this.$t('stats.ended')],
        colors: ['#d4526e', '#546e7a', '#33b2df', '#13d8aa'],
        plotOptions: {
          pie: {
            expandOnClick: false,
          },
        },
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'center',
          fontSize: '15px',
          fontFamily: 'Roboto',
        },
      },
    };
  },
  methods: {
    toggle(row) {
      this.$refs.table.toggleDetails(row);
    },
    printStats() {
      console.log(this.series2); // notStarted, train, anchor, done
      console.log(this.assignment_courses); // curr_course, curr_page, user_id.firstname/lastname/fullName
      this.$store.dispatch('Assignment/print_stats', {
        chart: this.series2,
        list: this.assignment_courses,
        assignment_name: this.curr_assignment.name,
        groupname: this.curr_group,
      }).then((result) => {
        console.log(result);
      });
    },
    get_csv() {
      console.log(this.series2); // notStarted, train, anchor, done
      console.log(this.assignment_courses); // curr_course, curr_page, user_id.firstname/lastname/fullName
      this.$store.dispatch('Assignment/get_csv', {
        chart: this.series2,
        list: this.assignment_courses,
        assignment_name: this.curr_assignment.name,
        groupname: this.curr_group,
      }).then((result) => {
        console.log(result);
      });
    },
    changeDone(props) {
      let newStatus;
      if (props.row.status === 12) {
        this.assignment_courses[props.index].status = 11;
        newStatus = 11;
      } else {
        this.assignment_courses[props.index].status = 12;
        newStatus = 12;
      }
      this.$store.dispatch('Course/save_course_status', { status: newStatus, course_id: props.row.id });
    },
  },
  computed: {
    curr_assignment() {
      return this.$store.getters['Assignment/get_assignment'];
    },
    curr_group() {
      return this.$store.getters['Groups/get_group'].name;
    },
    assignment_courses() {
      return this.$store.getters['Assignment/get_courses'].filter((course) => course != null);
    },
    live() {
      return this.$store.getters['Course/get_course'].type === 'live';
    },
    member_length() {
      return this.$store.getters['Assignment/get_courses'].length;
    },
    train_length() {
      return this.$store.getters['Course/princi'].length;
    },
    anchor_length() {
      return this.$store.getters['Course/anchors'].length;
    },
    units_length() {
      return this.$store.getters['Course/units_length'];
    },
    loading() {
      return this.$store.getters['Course/loading'];
    },
    noPW() {
      return this.assignment_courses.filter((course) => course.user_id.login === 'nein').length;
    },
    barOptions() {
      const xaxis = {
        categories: [],
      };
      for (let i = 0; i < this.units_length; i += 1) {
        xaxis.categories.push(i + 1);
      }
      xaxis.categories.push('B');
      return {
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis,
        title: {
          text: this.$t('stats.progress-active-user'),
          margin: 30,
          align: 'left',
          style: {
            fontSize: '25px',
          },
        },
        colors: [this.$store.getters['User/client'].pcolor],
      };
    },
    series1() {
      const array = [{ name: this.$t('stats.user'), data: [] }];
      for (let i = 0; i < this.units_length; i += 1) {
        let membersAt = 0;
        let membersDone = 0;
        if (i === this.units_length - 1) {
          membersAt = this.assignment_courses.filter((course) => course.curr_unit === i && course.status !== 12).length;
          membersDone = this.assignment_courses.filter((course) => course.status === 12).length;
        } else {
          membersAt = this.assignment_courses.filter((course) => course.curr_unit === i && course.user_id.login === 'ja').length;
        }
        array[0].data.push(membersAt);
        if (i === this.units_length - 1) array[0].data.push(membersDone);
      }
      return array;
    },
    series2() {
      const array = [];
      let notStarted = 0;
      let train = 0;
      let anchor = 0;
      let done = 0;
      for (let i = 0; i < this.assignment_courses.length; i += 1) {
        if (this.assignment_courses[i].status === undefined) {
          console.log(this.assignment_courses[i]);
        }
        const status = this.assignment_courses[i].status;
        const currUnit = this.assignment_courses[i].curr_unit;
        if (this.assignment_courses[i].user_id.login === 'nein') notStarted += 1;
        else if (status === 12) done += 1;
        else if (currUnit >= 0 && currUnit < this.train_length) train += 1;
        else if (currUnit >= this.train_length && currUnit < this.anchor_length + this.train_length) anchor += 1;
      }
      array.push(notStarted); array.push(train); array.push(anchor); array.push(done);
      return array;
    },
  },
};
</script>

<style lang="scss" scoped>
.stats {
    margin-top: 3.899rem;
  .table {
    padding: 0rem 1rem;
    margin-top: 2rem;
  }
}

.stats-title {
  display: inline;
  text-transform: uppercase;
  font-size: 3em;
  padding-left: 1rem;
  padding-top: 2rem;
}

.stats-subtitle {
  color: white !important;
  font-size: 2rem;
  padding-left: 1rem;
  padding-top: .5rem;
}

.fat-text {
  font-family: "Roboto";
  font-weight: 900;
}

.column {
  margin: 0 auto;
}

h2 {
  font-size: 1.5rem;
}

.no-pw {
  margin-top: 2rem;
}

#chart {
  max-width: 600px;
  margin: 35px auto;
}

.background-color-client {
  background-color: var(--secondary-color);
  color: var(--secondary-color-text);
}

</style>
