<!-- eslint-disable max-len -->
<template>
  <section>
    <Navbar />
    <section class="edit fixed-nav">
      <section class="hero" :style="liveStyling">
        <div>
          <div>
            <div class="top-edit-row">
              <p class="course-name">{{ curr_course_type }}</p>
              <p class="course-name fat-text">{{ curr_course.name }}</p>
            </div>
            <b-modal
            v-model="titleModal"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog">
              <div class="modal-background" @click="toggleTitleModal()"></div>
                <div class="modal-content title-card">
                  <div class="columns">
                    <div class="column">
                      <b-field :label="$t('course-edit.program-title')">
                        <b-input
                        v-model="curr_course.programtitle">
                        </b-input>
                      </b-field>
                      <b-field :label="$t('course-edit.subtitle')">
                        <b-input :placeholder="$t('course-edit.subtitle-placeholder')"
                        v-model="curr_course.subtitle">
                        </b-input>
                      </b-field>
                      <b-field :label="$t('course-edit.internal-name')">
                        <b-input :placeholder="$t('course-edit.internal-name-placeholder')"
                        style="max-width: 500px;"
                        v-model="curr_course.name">
                        </b-input>
                      </b-field>
                      <b-field :label="$t('course-edit.tech-name')">
                        <b-input v-model="curr_course.techname"> </b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field :label="$t('course-edit.img-path')">
                        <Upload class="upload" v-model="course_img"
                        @input="upload_course_img($event, payload)"
                        :mimetype="'image/*'" :multi="false"
                        style="width: 90%; display: inline-flex; padding-right: 5px;" />
                        <img v-if="curr_course.img" :src="curr_course.img" style="height: 130px;">
                      </b-field>
                      <b-field :label="$t('course-edit.dashboard-description')">
                        <textarea class="textarea"
                        v-model="curr_course.desc">
                        </textarea>
                      </b-field>
                      <b-field v-if="!live" :label="$t('course-edit.external-use')">
                        <b-switch v-model="curr_course.external_use"></b-switch>
                      </b-field>
                      <div v-if="tlte">
                        <b-field :label="$t('course-edit.values')">
                          <div>
                            <input class="input" v-model="value" :placeholder="$t('course-edit.value-placeholder')">
                          </div>
                        </b-field>
                        <textarea class="textarea" v-model="desc"
                        :placeholder="$t('course-edit.description')"></textarea>
                        <Button @click="addValue" :type="'zoom'" :text="$t('course-edit.add-value')"/>
                        <div class="display-v">
                          <div v-for="(value, index) in values" :key="value.id">
                            <h2 class="inline">{{ value.text }}</h2>
                            <span @click="delValue(index)">
                              <font-awesome-icon class="icon red" :icon="['fas', 'times']" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button v-if="!tlte" :type="'zoom'" :text="$t('common.close')"
                  @click="toggleTitleModal()"/>
                  <Button v-else :type="'zoom'" :text="$t('course-edit.create')"
                  @click="createTe()"/>
                </div>
              <button class="modal-close is-large" @click="toggleTitleModal()"
              aria-label="close"></button>
            </b-modal>
          </div>
          <div v-if="type !== 'live'" id="sidebar" class="sidebar" @mouseover="toggleSidebar()" @mouseout="toggleSidebar()">
            <div id="sidebar-content" class="sidebar-content">
              <Button :text="'Titel / Dashboard'" @click="toggleTitleModal()"
              :type="'hasIcon'" :icon="['fa', 'pen']" />
              <h3 style="margin-top: 1rem;">Aktuelle Einheit</h3>
              <b-dropdown :scrollable="true" :max-height="400"
              v-if="!tlte" aria-role="list">
                <template #trigger="{ active }">
                  <Button class="dd-button"
                  :type="'hasIcon'" :icon="['fas', active ? 'caret-up' : 'caret-down']"
                  :text="curr_unit_obj.meta.title" />
                </template>
                <span v-if="type !== 'mindtrigger'">
                  <h2 class="dd-title">{{ $t('course-edit.units') }}</h2>
                  <b-dropdown-item aria-role="listitem" v-for="(entry, index) in princ"
                  :key="entry.id" @click="changeUnit(entry, index)">
                  {{ entry.meta.title }}
                  </b-dropdown-item>
                  <b-dropdown-item aria-role="listitem" @click="add_unit('train')">
                    {{ $t('course-edit.new-unit') }}
                  </b-dropdown-item>
                  <hr>
                </span>
                <h2 v-if="mindtrigger" class="dd-title">{{ $t('common.mindtrigger') }}</h2>
                <h2 v-else class="dd-title">{{ $t('course-edit.anchors') }}</h2>
                <b-dropdown-item aria-role="listitem" v-for="(entry, index) in anchors"
                :key="entry.id" @click="changeUnit(entry, index)">
                {{ entry.meta.title }}
                </b-dropdown-item>
                <b-dropdown-item v-if="mindtrigger"
                aria-role="listitem" @click="add_unit('anchor')">
                {{ $t('course-edit.new-mindtrigger') }}
                </b-dropdown-item>
                <b-dropdown-item v-else aria-role="listitem" @click="add_unit('anchor')">
                {{ $t('course-edit.new-anchor') }}
                </b-dropdown-item>
              </b-dropdown>
              <Button :text="'Aktuelle Einheit löschen'" @click="del_unit(curr_unit_obj, currentMenu.id)" :type="'hasIcon'" :icon="['fas', 'trash']" class="red" />
              <b-field :label="$t('course-edit.title1')">
                <b-input v-model="curr_unit_obj.meta.title">
                </b-input>
              </b-field>
              <b-field :label="$t('course-edit.title2')">
                <b-input v-model="curr_unit_obj.meta.title2">
                </b-input>
              </b-field>
              <b-field :label="$t('course-edit.mouse-over-text')">
                <b-input v-model="curr_unit_obj.meta.mouse_over_text"
                :placeholder="$t('course-edit.mouse-over-placeholder')">
                </b-input>
              </b-field>
              <b-field :label="$t('course-edit.time')">
                <b-input v-model="curr_unit_obj.meta.time">
                </b-input>
              </b-field>
              <div class="controls">
                <Button :text="$t('common.save')" @click="save" :type="'zoom'"/>
                <Button :text="$t('course-edit.copy')" @click="copy" :type="'zoom'"/>
                <Button :text="$t('course-edit.test')" @click="test" :type="'zoom'"/>
              </div>
            </div>
          </div>
          <div v-if="type !== 'live'" class="edit-view">
            <div class="">
              <MultipleTasks v-bind:editmode="this.editmode"
              v-bind:unit="curr_unit_obj"/>
              <div class="columns add-box has-text-centered">
                <div class="plus-container">
                  <font-awesome-icon class="plus-icon" :icon="['fa', 'plus']" />
                </div>
                <div @click="add_check" class="column add-page">
                  <div class="icon-background">
                    <font-awesome-icon class="icon" :icon="['fa', 'question']" />
                  </div>
                  <span class="add-text">{{ $t('course-edit.add-check') }}</span>
                </div>
                <div @click="add_pquestion" class="column add-page">
                  <div class="icon-background">
                    <font-awesome-icon class="icon" :icon="['fa', 'comment']" />
                  </div>
                  <span class="add-text">{{ $t('course-edit.add-pquestion') }}</span>
                </div>
                <div @click="add_mcquestion" class="column add-page">
                  <div class="icon-background">
                    <font-awesome-icon class="icon" :icon="['fa', 'check-square']" />
                  </div>
                  <span class="add-text">{{ $t('course-edit.add-mcquestion') }}</span>
                </div>
                <div @click="add_video" class="column add-page">
                  <div class="icon-background">
                    <font-awesome-icon class="icon" :icon="['fa', 'play']" />
                  </div>
                  <span class="add-text">{{ $t('course-edit.add-video') }}</span>
                </div>
                <div @click="add_PDF" class="column add-page">
                  <div class="icon-background">
                    <font-awesome-icon class="icon" :icon="['fa', 'file']" />
                  </div>
                  <span class="add-text">{{ $t('course-edit.add-pdf') }}</span>
                </div>
                <div @click="add_justtext" class="column add-page">
                  <div class="icon-background">
                    <font-awesome-icon class="icon" :icon="['fa', 'align-left']" />
                  </div>
                  <span class="add-text">{{ $t('course-edit.add-text') }}</span>
                </div>
                <div @click="add_paywall" class="column add-page">
                  <div class="icon-background">
                    <font-awesome-icon class="icon" :icon="['fa', 'wallet']" />
                  </div>
                  <span class="add-text">{{ $t('course-edit.add-paywall') }}</span>
                </div>
              </div>
            </div>
          </div>
        <Button v-if="live" :text="'Titel / Dashboard'" @click="toggleTitleModal()"
            :type="'hasIcon'" :icon="['fa', 'pen']" style="float: right" />
        <CourseTabs class="is-full column" style="width: 100%; padding: 1rem 0 !important" :editmode="true" />
        <div v-if="live" class="controls" style="width: 30%; float: right">
          <Button :text="$t('common.save')" @click="save" :type="'zoom'"/>
          <Button :text="$t('course-edit.copy')" @click="copy" :type="'zoom'"/>
          <Button :text="$t('course-edit.test')" @click="test" :type="'zoom'"/>
        </div>
        </div>
      </section>
    </section>
    <Footer />
  </section>
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import Button from '@/traincom/components/Common/Button.vue';
import MultipleTasks from '@/traincom/components/pagetypes/MultipleTasks.vue';
import Upload from '@/traincom/components/Common/Upload.vue';
import Navbar from '../../components/Common/Navbar.vue';
import CourseTabs from '../components/Course/CourseTabs.vue';
import Footer from '../../components/Common/Footer.vue';

export default {
  components: {
    Navbar,
    Footer,
    MultipleTasks,
    Upload,
    Button,
    CourseTabs,
  },
  created() {
    console.log(this.$route.params.name);
    if (this.$route.params.name !== 'new') {
      this.titleModal = false;
      this.$store.dispatch('Course/load', this.$route.params.name);
    }
  },
  beforeDestroy() {
    this.$store.dispatch('Course/save');
    this.$store.commit('Course/clear_course');
  },
  data() {
    return {
      mini: true,
      titleModal: true,
      editmode: true,
      newCompModal: false,
      course_img: {},
      currentMenu: {
        id: 0,
        title: this.$t('course-edit.dropdown-title'),
      },
      value: '',
      values: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters['User/user'];
    },
    type() {
      return this.$store.getters['Course/curr_course'].type;
    },
    tlte() {
      return (this.type === 'tl' || this.type === 'te');
    },
    mindtrigger() {
      return this.type === 'mindtrigger';
    },
    live() {
      return this.type === 'live';
    },
    liveStyling() {
      if (this.live) return 'margin-right: 7rem';
      return 'width: 57%;';
    },
    curr_course_type() {
      if (this.type === 'training') return this.$t('common.training');
      if (this.type === 'mindtrigger') return this.$t('course-edit.mindtrigger-series');
      return this.$t('common.training');
    },
    client() {
      return this.$store.getters['User/client'];
    },
    curr_unit_obj() {
      return this.$store.getters['Course/curr_unit_obj'];
    },
    curr_course() {
      return this.$store.getters['Course/curr_course'];
    },
    princ() {
      return this.$store.getters['Course/princi'];
    },
    anchors() {
      return this.$store.getters['Course/anchors'];
    },
  },
  methods: {
    save() {
      this.$store.commit('Course/reset_progress');
      this.$store.dispatch('Course/save').then(() => {
        this.$router.replace({ path: `/edit/${this.curr_course.id}` });
        this.$buefy.toast.open({
          message: this.$t('course-edit.save-success'),
          type: 'is-success',
        });
      });
    },
    toggleSidebar() {
      if (this.mini) {
        document.getElementById('sidebar').style.width = '28%';
        this.mini = false;
      } else {
        document.getElementById('sidebar').style.width = '28%';
        this.mini = true;
      }
    },
    test() {
      this.$store.dispatch('Course/test').then(() => {
        this.$buefy.toast.open({
          message: this.$t('course-edit.test-success'),
          type: 'is-success',
        });
      });
    },
    copy() {
      this.$store.dispatch('Course/copy').then(() => {
        this.$router.replace({ path: `/course/${this.curr_course.id}` });
        this.$buefy.toast.open({
          message: this.$t('course-edit.copy-success'),
          type: 'is-success',
        });
      });
    },
    addValue() {
      if (this.value === '' || this.desc === '') return;
      const obj = {
        text: this.value, desc: this.desc,
      };
      this.values.push(obj);
      this.curr_course.meta.values = this.values;
      this.value = ''; this.desc = '';
    },
    delValue(index) {
      this.values.splice(index, 1);
    },
    changeUnit(entry, index) {
      this.currentMenu.id = index;
      if (entry.meta.utype === 'anchor') {
        index += this.princ.length;
      }
      console.log(index);
      this.$store.commit('Course/editor_change_unit', index);
      this.$forceUpdate();
    },
    add_pquestion() {
      const obj = {
        type: 'pquestion',
        text: '',
        answer: '',
        note_activated: true,
      };
      this.$store.commit('Course/add_page', obj);
    },
    add_mcquestion() {
      const obj = {
        type: 'questions',
        subtype: '',
        min_answers: null,
        note_activated: true,
        question:
          {
            q: '',
            options: [
            ],
          },
      };
      this.$store.commit('Course/add_page', obj);
    },
    add_te_justtext(ti, te) {
      const obj = {
        type: 'justtext',
        title: ti,
        text: te,
        note_activated: true,
      };
      this.$store.commit('Course/add_page', obj);
    },
    add_te_question(question, desc) {
      const obj = {
        type: 'questions',
        subtype: 'sc',
        min_answers: null,
        note_activated: true,
        question:
          {
            q: question,
            options: [
              {
                a: true, o: '0 = trifft gar nicht zu', u: false, img: '/images/3sad.png',
              },
              {
                a: true, o: '1 = trifft kaum zu', u: false, img: '/images/2sad.png',
              },
              {
                a: true, o: '2 = trifft etwas zu', u: false, img: '/images/1sad.png',
              },
              {
                a: true, o: '3 = trifft eher zu', u: false, img: '/images/1happy.png',
              },
              {
                a: true, o: '4 = trifft weitestgehend zu', u: false, img: '/images/2happy.png',
              },
              {
                a: true, o: '5 = trifft voll zu', u: false, img: '/images/3happy.png',
              },
            ],
          },
      };
      if (desc) {
        obj.subtext = desc;
      } else obj.subtext = '';
      this.$store.commit('Course/add_page', obj);
    },
    add_te_pquestion(string) {
      const obj = {
        type: 'pquestion',
        text: string,
        answer: '',
        note_activated: true,
      };
      this.$store.commit('Course/add_page', obj);
    },
    add_video() {
      const obj = {
        type: 'video',
        content: '',
        poster: '',
        watches: 0,
        note_activated: true,
      };
      this.$store.commit('Course/add_page', obj);
    },
    add_PDF() {
      const obj = {
        type: 'pdf',
        content: '',
        note_activated: true,
      };
      this.$store.commit('Course/add_page', obj);
    },
    add_justtext() {
      const obj = {
        type: 'justtext',
        title: '',
        text: '',
        note_activated: true,
      };
      this.$store.commit('Course/add_page', obj);
    },
    add_paywall() {
      const obj = {
        type: 'pw',
        title: '',
        text: '',
        pw: '',
      };
      this.$store.commit('Course/add_page', obj);
    },
    add_check() {
      const obj = { type: 'check' };
      this.$store.commit('Course/add_page', obj);
    },
    add_unit(type) {
      let text = '';
      let index;
      index = this.princ.length;
      text = this.$t('course-edit.this-new-unit');
      if (type === 'anchor') {
        index = this.anchors.length;
        text = this.$t('course-edit.this-new-anchor');
      }
      const obj = {
        meta: {
          state: 'fa fa-lock',
          utype: type,
          title: text,
          title2: '',
          mouse_over_text: '',
          time: '',
        },
        pages: [],
      };
      this.$store.commit('Course/add_unit', obj);
      this.changeUnit(obj, index);
    },
    del_unit(entry, index) {
      this.$buefy.dialog.confirm({
        title: this.$t('course-edit.del-unit'),
        message: this.$t('course-edit.del-unit-text'),
        confirmText: this.$t('common.yes'),
        cancelText: this.$t('common.no'),
        hasIcon: true,
        onConfirm: () => { // Loop to add every Assignment-Course to the user
          if (this.curr_course.units.length <= 1) {
            this.$buefy.toast.open({
              message: this.$t('course-edit.del-unit-error'),
              type: 'is-danger',
            });
            return;
          }
          this.$store.commit('Course/reset_progress');
          if (entry.meta.utype === 'anchor') {
            index += this.princ.length;
          }
          this.$store.commit('Course/del_unit', index);
        },
      });
    },
    del_page(index) {
      this.$store.commit('Course/del_page', index);
    },
    toggleTitleModal() {
      this.titleModal = !this.titleModal;
    },
    async upload_course_img(courseImg) {
      console.log(courseImg);
      const res = await this.$store.dispatch('Uploads/upload_course_img', courseImg);
      this.curr_course.img = res.data;
    },
    createTe() {
      this.add_te_justtext('Herzlich Willkommen!', 'Vielen Dank für Ihre Bereitschaft, sich aktiv zu beteiligen. Die Befragung dauert nur wenige Minuten. Sie umfasst insgesamt 33 Aussagen, die in drei Bereiche aufgeteilt sind. Bitte klicken Sie auf den Knopf „weiter“, um in den ersten Bereich zu gelangen.');
      this.add_te_justtext('Teil 1: KLAR', 'Es ist Aufgabe eines glaubwürdigen Arbeitgebers, die gewünschte Unternehmenskultur (also die Unternehmenswerte) KLAR an die Mitarbeiter:innen zu kommunizieren.  Die Frage ist also: Wurde Ihnen die Bedeutung der Unternehmenswerte erklärt und werden Sie auch regelmäßig daran erinnert?');
      for (let i = 0; i < this.values.length; i += 1) {
        const clear1 = `<b>Mir wurde erklärt, was unser Unternehmenswert '<b>${this.values[i].text}</b>' für meine Arbeit bedeutet.`;
        const clear2 = `<b>Ich werde regelmäßig - wenn es notwendig ist - von Führungskräften und Kolleg:innen an unseren Unternehmenswert '<b>${this.values[i].text}</b>' erinnert.`;
        this.add_te_question(clear1, `<b>Erläuterung zu dem Wert:</b> ${this.values[i].desc}`);
        this.add_te_question(clear2, `<b>Erläuterung zu dem Wert:</b> ${this.values[i].desc}`);
      }
      this.add_te_justtext('Teil 2: KONSEQUENT', 'Glaubwürdigkeit zeigt sich darin, dass das „Gesagte“ auch das „Gemeinte“ und das „Getane“ ist. Mit anderen Worten: Die gewünschte Kultur wird also durchgängig gelebt – von Führungskräften und von den Mitarbeiter:innen. Die Frage ist also: Wie KONSEQUENT werden die Werte von den Führungskräften vorgelebt und auch von allen beachtet?');
      for (let i = 0; i < this.values.length; i += 1) {
        const consequent1 = `<b>Unsere Führungskräfte</b> verhalten sich '<b>${this.values[i].text}</b>'.`;
        const consequent2 = `<b>Unser Team</b> verhält sich '<b>${this.values[i].text}</b>'.`;
        this.add_te_question(consequent1, `<b>Erläuterung zu dem Wert:</b> ${this.values[i].desc}`);
        this.add_te_question(consequent2, `<b>Erläuterung zu dem Wert:</b> ${this.values[i].desc}`);
      }
      this.add_te_question('In <b>unserem Team</b> werden keine Verstöße gegen unsere Unternehmenswerte geduldet.');
      this.add_te_question('<b>Führungskräfte</b>, mit denen ich zusammenarbeite, darf ich ansprechen, wenn sie gegen unsere Unternehmenswerte verstoßen.');
      this.add_te_justtext('Teil 3: KONSTRUKTIV', 'Ein KONSTRUKTIVER Umgang mit menschlicher Fehlbarkeit ist ein wichtiger Faktor einer guten Unternehmenskultur – und zeichnet deshalb auch glaubwürdige Arbeitgeber aus. Es geht also darum, wie man mit Eigenarten, Fehlern und Schwächen im Team, von Führungskräften und auch mit anderen Teams umgeht.');
      this.add_te_question('In <b>unserem Team</b> darf jeder sein, wie er ist - es wird aber erwartet, dass niemand den Teamerfolg gefährdet.');
      this.add_te_question('Auch <b>Führungskräfte</b> dürfen sein, wie sie sind - es wird aber erwartet, dass sie den Teamerfolg nicht gefährden.');
      this.add_te_question('Auch <b>andere Teams</b> dürfen sein, wie sie sind - es wird aber erwartet, dass sie den gemeinsamen Erfolg nicht gefährden.');
      this.add_te_question('In <b>unserem Team</b> sind Fehler erlaubt - wir erwarten aber, dass jeder aus seinen Fehlern lernt.');
      this.add_te_question('Auch <b>Führungskräfte</b> dürfen Fehler machen - wir erwarten aber, dass sie aus ihren Fehlern lernen.');
      this.add_te_question('Auch <b>andere Teams</b> dürfen Fehler machen - wir erwarten aber, dass sie aus ihren Fehlern lernen.');
      this.add_te_question('Wir unterstützen uns gegenseitig in <b>unserem Team</b> - erwarten aber, dass jeder dazulernen will.');
      this.add_te_question('Wir unterstützen <b>Führungskräfte</b>, wenn sie Schwächen haben - erwarten aber, dass sie dazulernen wollen.');
      this.add_te_question('Wir unterstützen andere <b>Teams</b>, wenn sie Schwächen haben - erwarten aber, dass sie dazulernen wollen.');
      this.add_te_justtext('ZUSAMMENFASSUNG', 'Ein glaubwürdiger Arbeitgeber verdient es, an Familie, Freunde und Bekannte weiterempfohlen zu werden. Die Frage ist also: Wie sehr sind Sie persönlich bereit, die BLG als Arbeitgeber zu empfehlen und warum ist dies für Sie so?');
      this.add_te_question('Ich würde unser Unternehmen als Arbeitgeber weiterempfehlen.');
      this.add_te_pquestion('Warum habe ich die letzte Frage so beantwortet ("Weiterempfehlung als Arbeitgeber")?');
      this.toggleTitleModal();
      this.save();
    },
  },
};
</script>

<style lang="scss" scoped>
.edit {
  font-family: 'Noto Sans';
  .sidebar {
    height: 100%;
    width: 28vw;
    padding: 1rem;
    padding-top: .2rem;
    position: fixed;
    z-index: 1;
    top: 3.899rem;
    right: 0;
    background-color: white;
    color: black;
    overflow-x: hidden;
    white-space: nowrap;
    b-input {
      min-width: 2rem;
    }
    .sidebar-content {
      transition: all .2s;
      transform: scale(97%);
    }
  }
  .hero .tabs ul {
    border-bottom-color: var(--primary-color) !important;
    border-bottom-width: 3px !important;
    font-weight: 400 !important;
    margin-bottom: 3rem;
  }
  .hero {
    margin-top: 1.5rem;
    padding: 0rem;
    margin-left: 7rem;
    color: black;
    .course-name {
      font-size: 1.6rem;
      display: inline;
      text-transform: uppercase;
    }
    .fat-text {
      font-family: "Noto Sans";
      padding-left: 1rem;
      font-weight: 900;
    }
    .course-subtitle {
      font-size: 2rem;
    }
    .min-width-top {
      min-width: 200px;
    }
    .top-edit-row {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
      .title-button {
        margin-left: 2rem;
        display: inline-flex;
      }
      .dropdown-button {
        margin-right: 1rem;
      }
    }
    a.dropdown-item {
      overflow: hidden !important;
      white-space: nowrap !important;
      text-overflow: ellipsis !important;
      max-width: 23rem;
    }
    .inline {
      display: inline-flex;
    }
    .taginput {
      max-width: 23em;
    }
    .right {
      position: absolute;
      right: 2rem;
    }
    .edit-view {
      min-height: 40vh;
      margin-top: 2rem;
    }
    .icon {
      :hover {
        cursor: pointer;
      }
    }

    .del-page {
      margin: 0;
      font-size: 2rem;
    }
    .stick {
      z-index: 2;
      @media only screen and (min-width: 769px) {
        top: 12rem;
        width: 15rem;
        right:0;
        bottom: -.5rem;
        position:fixed;
      }
      @media only screen and (min-width: 1170px) {
        width: 20rem;
      }
    }
  }

  .red {
    background-color: red !important;
    display: flex;
  }
  .add-box {
    aspect-ratio : 7 / 1; //increase with more page-types e.g. 8 / 1
    position: relative;
    cursor: pointer;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-left: 0 !important;
    margin-right: 0 !important;
    .add-page {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: .5s;
      opacity: 0;
    }
    .add-text {
      font-size: 1.2rem;
      transition: .5s;
    }
    .add-page:hover .icon-background{
      background-color: var(--primary-color);
      opacity: 0.8;
      transition: .5s;
    }
    .add-page:hover .add-text{
      font-size: 1.3rem;
      transition: .5s;
      color: var(--primary-color-text);
    }
  }
  .add-box:hover .add-page {
    opacity: 1;
    transition: .5s;
  }
  .add-box:hover .plus-container {
    opacity: 0;
    transition: .5s;
  }
  .plus-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    .plus-icon {
      transform: scale(300%);
      opacity: 0.35;
    }
    border: 2px solid rgb(222, 222, 222);
    border-radius: 5px;
  }
  .icon-background {
    transition: .5s;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: whitesmoke;
    opacity: 0.8;
    .icon {
      opacity: 0.1;
      transform: scale(500%);
    }
    z-index: -1;
  }
  .del-unit {
    font-size: 1.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    :hover {
      cursor: pointer;
    }
  }
  .client-colors {
    background-color: var(--secondary-color);
    color: var(--secondary-color-text) !important;
  }
  .title-card {
  color: black;
  background-color: white;
  border-radius: 15px;
  padding: 1rem;
  width: 50rem;
  font-weight: 450;
  }
  .dd-title {
    color: black;
    margin-left: .5rem;
  }
  .dd-button {
    margin-left: 0;
    max-width: 25.5vw;
  }
  .controls {
    display: grid;
    padding-bottom: 3rem;
  }
  .red {
  color: red;
  }
  .notification {
    margin: 0 !important;
  }
  .display-v {
    margin-top: 1rem;
    min-height: 15rem;
  }
}
</style>
