<template>
  <section class="blank-text">
    <div class="notification">
      <span v-if="editmode" @click="del_page()" class="del">
        <font-awesome-icon class="icon del-page red" :icon="['fas', 'times']" />
      </span>
      <div v-for="(pair, index) in page.pairs" :key="pair.id" class="pairs box">
        <span v-for="part in pair.parts" :key="part.id" class="pair">
          <span v-if="!editmode">
            <h2 v-if="part.type === 'text'" class="text-content">{{ part.content }}</h2>
            <input v-if="part.type === 'blank'" v-model="part.answer" class="pair-input input">
          </span>
          <span v-if="editmode">
            <input v-if="part.type === 'text'" v-model="part.content"
            class="pair-input input edit-input" placeholder="Angezeigtes Wort">
            <input v-if="part.type === 'blank'" v-model="part.content"
            class="pair-input input edit-input" placeholder="Gesuchtes Wort">
          </span>
        </span>
        <span v-if="editmode" @click="del_pair(index)">
          <font-awesome-icon class="icon red" :icon="['fas', 'times']" />
        </span>
      </div>
      <button @click="add_pair()" v-if="editmode" class="button">Paar hinzufügen</button>
    </div>
  </section>
</template>

<script>
export default {
  props: ['page', 'editmode'],

  methods: {
    add_pair() {
      const pair = {
        parts: [
          { type: 'text', content: '' },
          { type: 'blank', content: '', answer: '' },
        ],
      };
      this.page.pairs.push(pair);
    },
    del_pair(i) {
      this.page.pairs.splice(i, 1);
    },
    del_page() {
      this.$emit('del_page');
    },
  },
};
</script>

<style lang="scss" scoped>
.text-content {
  color: black;
  display: inline;
  margin-right: 1rem;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 1.2rem;
}

.input {
  width: 12rem;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 1.2rem;
}

.pairs {
  margin: 1rem;
  margin-top: 2rem;
  height: 5rem;
}

.red {
  color: red;
}

.icon {
  font-size: 1.2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  :hover {
    cursor: pointer;
  }
}

.red {
    color: red;
  }

.del-page {
  margin: 0;
  font-size: 2rem;
}

.edit-input {
  margin-right: 1rem;
}

.del {
  float: right;
}

</style>
