<!-- eslint-disable max-len -->

<template>
  <div>
    <Navbar />
    <section class="stats">
      <section class="hero is-small">
        <div class="responsive-pad">
          <h1 class="courses-title">{{ $t('my-courses.title-thin') }} </h1> <h1 class="courses-title fat-text">{{ $t('my-courses.title-bold') }}</h1>
        </div>
      </section>
      <b-skeleton height="500px" width="90%" position="is-centered" class="responsive-pad" :active="loading" :animated="true"></b-skeleton>
      <b-tabs v-if="!loading" v-model="activeTab" position="" size="is-medium"
        class="tab responsive-pad tab-custom is-centered">
        <b-tab-item label="Trainings">
          <b-table
            class="table"
            :data="trainings"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :default-sort-direction="defaultSortDirection"
            :sort-icon="sortIcon"
            :sort-icon-size="sortIconSize"
            :hoverable="true"
            :selected.sync="selected"
            @click="select($event)"
            @dblclick="edit($event)"
            default-sort="lastname"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page">
              <b-table-column searchable field="name" :label="$t('my-courses.trainings')" sortable v-slot="props">
                {{ props.row.name }}
              </b-table-column>
              <b-table-column v-slot="props">
                <b-dropdown aria-role="list">
                  <template #trigger="">
                    <span class="icon-frame">
                      <font-awesome-icon :icon="['fa', 'ellipsis-h']" />
                    </span>
                  </template>
                  <b-dropdown-item class="options-dropdown-delete"
                  @click="del(props.row)">
                    <font-awesome-icon :icon="['fa', 'trash']" />
                    {{ $t('common.delete') }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-table-column>
          </b-table>
          <Button @click="newCourse()" :type="'zoom'" :text="$t('my-courses.new-training')" />
        </b-tab-item>
        <b-tab-item :label="$t('common.mindtrigger')">
          <b-table
            class="table"
            :data="mindtrigger"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :sort-icon="sortIcon"
            :sort-icon-size="sortIconSize"
            :hoverable="true"
            :selected.sync="selected"
            @click="select($event)"
            @dblclick="edit($event)"
            default-sort="lastname"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page">
              <b-table-column searchable field="name" :label="$t('my-courses.title')" sortable v-slot="props">
                {{ props.row.name }} - {{ props.row.subtitle }}
              </b-table-column>
              <b-table-column v-slot="props">
                <b-dropdown aria-role="list">
                  <template #trigger="">
                    <span class="icon-frame">
                      <font-awesome-icon :icon="['fa', 'ellipsis-h']" />
                    </span>
                  </template>
                  <b-dropdown-item class="options-dropdown-delete"
                  @click="del(props.row)">
                    <font-awesome-icon :icon="['fa', 'trash']" />
                    {{ $t('common.delete') }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-table-column>
          </b-table>
          <Button @click="newMindTrigger()" :type="'zoom'" :text="$t('my-courses.new-mindtrigger')" />
        </b-tab-item>
        <b-tab-item :label="$t('my-courses.trusted-leader')">
          <b-table
            class="table"
            :data="trustedLeader"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :default-sort-direction="defaultSortDirection"
            :sort-icon="sortIcon"
            :sort-icon-size="sortIconSize"
            :hoverable="true"
            :selected.sync="selected"
            @click="select($event)"
            @dblclick="edit($event)"
            default-sort="lastname"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page">
              <b-table-column searchable field="name" :label="$t('my-courses.title')" sortable v-slot="props">
                {{ props.row.name }} - {{ props.row.subtitle }}
              </b-table-column>
              <b-table-column v-slot="props">
                <b-dropdown aria-role="list">
                  <template #trigger="">
                    <span class="icon-frame">
                      <font-awesome-icon :icon="['fa', 'ellipsis-h']" />
                    </span>
                  </template>
                  <b-dropdown-item class="options-dropdown-delete"
                  @click="del(props.row)">
                    <font-awesome-icon :icon="['fa', 'trash']" />
                    {{ $t('common.delete') }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-table-column>
          </b-table>
          <Button @click="newTrustedLeader()" :type="'zoom'" :text="$t('my-courses.new-trusted-leader')" />
        </b-tab-item>
        <b-tab-item :label="$t('my-courses.trusted-employer')">
          <b-table
            class="table"
            :data="trustedEmployer"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :default-sort-direction="defaultSortDirection"
            :sort-icon="sortIcon"
            :sort-icon-size="sortIconSize"
            :hoverable="true"
            :selected.sync="selected"
            @click="select($event)"
            @dblclick="edit($event)"
            default-sort="lastname"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page">
              <b-table-column searchable field="name" :label="$t('my-courses.title')" sortable v-slot="props">
                {{ props.row.name }} - {{ props.row.subtitle }}
              </b-table-column>
              <b-table-column v-slot="props">
                <b-dropdown aria-role="list">
                  <template #trigger="">
                    <span class="icon-frame">
                      <font-awesome-icon :icon="['fa', 'ellipsis-h']" />
                    </span>
                  </template>
                  <b-dropdown-item class="options-dropdown-delete"
                  @click="del(props.row)">
                    <font-awesome-icon :icon="['fa', 'trash']" />
                    {{ $t('common.delete') }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-table-column>
          </b-table>
          <Button @click="newTrustedEmployer()" :type="'zoom'" :text="$t('my-courses.new-trusted-employer')" />
        </b-tab-item>
        <b-tab-item :label="$t('my-courses.live-trainings')">
          <b-table
            class="table"
            :data="liveTrainings"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :default-sort-direction="defaultSortDirection"
            :sort-icon="sortIcon"
            :sort-icon-size="sortIconSize"
            :hoverable="true"
            :selected.sync="selected"
            @click="select($event)"
            @dblclick="edit($event)"
            default-sort="lastname"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page">
              <b-table-column searchable field="name" :label="$t('my-courses.title')" sortable v-slot="props">
                {{ props.row.name }} - {{ props.row.subtitle }}
              </b-table-column>
              <b-table-column v-slot="props">
                <b-dropdown aria-role="list">
                  <template #trigger="">
                    <span class="icon-frame">
                      <font-awesome-icon :icon="['fa', 'ellipsis-h']" />
                    </span>
                  </template>
                  <b-dropdown-item class="options-dropdown-delete"
                  @click="del(props.row)">
                    <font-awesome-icon :icon="['fa', 'trash']" />
                    {{ $t('common.delete') }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-table-column>
          </b-table>
          <Button @click="newLiveTraining()" :type="'zoom'" :text="$t('my-courses.new-live-training')" />
        </b-tab-item>
      </b-tabs>
    </section>
    <Footer />
  </div>
</template>

<script>
import Button from '@/traincom/components/Common/Button.vue';
import Navbar from '../../components/Common/Navbar.vue';
import Footer from '../../components/Common/Footer.vue';

export default {
  components: {
    Navbar,
    Button,
    Footer,
  },

  created() {
    this.$store.dispatch('Courses/load_acc_courses');
  },

  data() {
    return {
      selected: {},
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 10,
      activeTab: 0,
    };
  },
  computed: {
    trainings() {
      return this.$store.getters['Courses/acc_courses'].filter((course) => course.type === 'training').reverse();
    },
    mindtrigger() {
      return this.$store.getters['Courses/acc_courses'].filter((course) => course.type === 'mindtrigger').reverse();
    },
    trustedLeader() {
      return this.$store.getters['Courses/acc_courses'].filter((course) => course.type === 'tl').reverse();
    },
    trustedEmployer() {
      return this.$store.getters['Courses/acc_courses'].filter((course) => course.type === 'te').reverse();
    },
    liveTrainings() {
      return this.$store.getters['Courses/acc_courses'].filter((course) => course.type === 'live').reverse();
    },
    loading() {
      return this.$store.getters['Courses/loading'];
    },
  },
  methods: {
    toggle(row) {
      this.$refs.table.toggleDetails(row);
    },
    edit(course) {
      const p = `edit/${course.id}`;
      this.$router.push(p);
    },
    select(course) {
      this.selected = course;
    },
    newCourse() {
      this.$store.commit('Course/new_course', 'training');
      this.$router.push('edit/new');
      this.$store.commit('Course/set_loading', true);
    },
    newMindTrigger() {
      this.$store.commit('Course/new_course', 'mindtrigger');
      this.$router.push('edit/new');
      this.$store.commit('Course/set_loading', true);
    },
    newTrustedLeader() {
      this.$store.commit('Course/new_course', 'tl');
      this.$router.push('edit/new');
      this.$store.commit('Course/set_loading', true);
    },
    newTrustedEmployer() {
      this.$store.commit('Course/new_course', 'te');
      this.$router.push('edit/new');
      this.$store.commit('Course/set_loading', true);
    },
    newLiveTraining() {
      this.$store.commit('Course/new_course', 'live');
      this.$router.push('edit/new');
      this.$store.commit('Course/set_loading', true);
    },
    del(row) {
      this.$buefy.dialog.confirm({
        title: this.$t('my-courses.del-training'),
        message: this.$t('my-courses.del-text'),
        confirmText: this.$t('common.delete'),
        cancelText: this.$t('common.cancel'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch('Courses/delete', row.id);
        },
      });
    },
  },
};
</script>

<style lang="scss">
.is-primary {
  background-color: var(--primary-color)!important;
  color: var(--primary-color-text);
  margin-left: 2rem;
  margin-bottom: 1rem;
  font-weight: 550;
}

.stats {
  margin-top: 3.899rem;
  background-color: white;
}

table:hover {
  cursor: pointer;
}

.table {
  Button {
    font-size: .9rem;
    height: 2.2rem;
    padding: .6rem;
  }
  tr.is-selected {
    background-color: var(--secondary-color)!important;
  }
  tr {
    cursor: pointer;
  }
}

.options-dropdown-delete {
  background-color: #f14668 !important;
  border-color: transparent !important;
  color: #fff !important;
}

.courses-title {
  display: inline;
  text-transform: uppercase;
  font-size: 3em;
  font-family: "Noto Sans";
}

.hero {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.fat-text {
  font-weight: 900;
}

.background-color-client {
  background-color: var(--secondary-color);
  color: var(--secondary-color-text);
}

.tab-content {
  padding: 0 !important;
}
</style>
