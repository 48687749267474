<!-- eslint-disable max-len -->

<template>
  <section id="groups" class="groups">
    <Navbar />
    <section class="hero fixed-nav is-small">
      <div class="responsive-pad">
        <h1 class="admin-title">{{ $t('groups.title-thin') }} </h1>
        <h1 class="admin-title fat-text"> {{ $t('groups.title-bold') }}</h1>
      </div>
    </section>
    <div class="client responsive-pad">
      <b-dropdown
      animation="none"
      :scrollable="true"
      :max-height="'350px'"
      aria-role="list">
        <button class="button dropdown-button" type="button" slot="trigger">
          <template>
            <span v-if="currentMenu.title !== ''" class="dropdown-title">
              {{ currentMenu.title }}
            </span>
            <span v-else>
              {{ $t('groups.dropdown-placeholder') }}
            </span>
          </template>
          <b-icon icon="menu-down"></b-icon>
        </button>
        <b-dropdown-item v-for="(client, index) in clients" class="dropdown-list"
        :key="client.id" :value="client.name" aria-role="listitem"
        @click="change_client(client.name, client.id, index)">
          {{ client.name }}
        </b-dropdown-item>
      </b-dropdown>
      <AddClient v-if="editClient >= 0"
        :edit="true" :editClient="editClient" />
      <AddClient />
    </div>
    <section class="edit-section responsive-pad">
      <div class="columns">
        <div v-if="clientSelected" class="column is-half group-column">
          <span>
            <h1 class="heading">
              <strong>{{ $t('groups.groups') }}</strong>
              <AddGroup class="add-group" v-on:addGroup="add_group($event)" />
            </h1>
          </span>
          <b-table
          class="table"
          :data="groups"
          :paginated="isPaginated"
          :per-page="perPage"
          :current-page.sync="currentPage1"
          :pagination-simple="isPaginationSimple"
          :pagination-position="paginationPosition"
          :default-sort-direction="defaultSortDirection"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          :selected.sync="selected_group"
          :hoverable="true"
          @click="change_group($event)"
          @dblclick="toggle_name_modal($event)"
          default-sort="lastname"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page">
            <b-table-column searchable field="name" :label="$t('groups.name')" sortable v-slot="props">
              {{ props.row.name }}
            </b-table-column>
            <b-table-column v-slot="props">
              <b-dropdown aria-role="list">
                <template #trigger="">
                  <span class="icon-frame">
                    <font-awesome-icon :icon="['fa', 'ellipsis-h']" />
                  </span>
                </template>
                <b-dropdown-item
                class="options-dropdown"
                @click="toggle_name_modal(props.row)">
                  <font-awesome-icon :icon="['fa', 'font']" />
                  {{ $t('groups.change-name') }}
                </b-dropdown-item>
                <b-dropdown-item v-if="assignments.length === 0"
                class="options-dropdown-delete"
                @click="del_group(props.row)">
                  <font-awesome-icon :icon="['fa', 'trash']" />
                  {{ $t('common.delete') }}
                </b-dropdown-item>
              </b-dropdown>
            </b-table-column>
          </b-table>
          <div v-if="name_modal" class="modal is-active">
          <div class="modal-background" @click="toggle_name_modal()"></div>
          <div class="modal-content card">
            <div class="container">
              <h3>{{ $t('groups.change-name-group') }}</h3>
              <b-field label="Gruppenname">
                <b-input v-model="to_change_group_name"></b-input>
              </b-field>
              <Button :type="'zoom'" @click="save_name()" :text="$t('common.save')" />
              <Button :type="'zoom'" @click="toggle_name_modal()" :text="$t('common.cancel')" />
            </div>
          </div>
          <button class="modal-close is-large" @click="toggle_name_modal()"
          aria-label="close"></button>
        </div>
        </div>
        <div v-if="groupSelected" class="column is-half member-column">
          <span>
            <h1 class="heading">
              <strong>{{ $t('groups.members') }}</strong>
              <AddMember class="add-group" :useCase="'group'"/>
            </h1>
          </span>
          <b-table
          class="table"
          :data="members"
          :paginated="isPaginated"
          :per-page="perPage"
          :current-page.sync="currentPage2"
          :pagination-simple="isPaginationSimple"
          :pagination-position="paginationPosition"
          :default-sort-direction="defaultSortDirection"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          :selected.sync="selected_user"
          :hoverable="true"
          @dblclick="toggle_user_modal($event)"
          default-sort="lastname"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page">
            <b-table-column searchable field="fullName" label="Name" sortable v-slot="props">
              {{ props.row.fullName }}
            </b-table-column>
            <b-table-column v-slot="props">
              <b-dropdown aria-role="list">
                <template #trigger="">
                  <span class="icon-frame">
                    <font-awesome-icon :icon="['fa', 'ellipsis-h']" />
                  </span>
                </template>
                <b-dropdown-item
                class="options-dropdown"
                @click="toggle_user_modal(props.row)">
                  <font-awesome-icon :icon="['fa', 'pen']" />
                  {{ $t('groups.edit') }}
                </b-dropdown-item>
                <b-dropdown-item
                class="options-dropdown-delete"
                @click="del_member(props.row)">
                  <font-awesome-icon :icon="['fa', 'trash']" />
                  {{ $t('common.delete') }}
                </b-dropdown-item>
              </b-dropdown>
            </b-table-column>
          </b-table>
          <div v-if="user_modal" class="modal is-active">
            <div class="modal-background" @click="toggle_user_modal()"></div>
            <div class="modal-card">
              <header class="modal-card-head">
                <p class="modal-card-title">{{ $t('groups.edit-user') }}</p>
                <button class="delete" aria-label="close" @click="toggle_user_modal()"></button>
              </header>
              <section class="modal-card-body">
                <b-field :label="$t('profile.attention')">
                  <b-input type="text" v-model="edit_user.attention"></b-input>
                </b-field>
                <b-field :label="$t('profile.name')">
                  <b-input type="text" v-model="edit_user.firstname"></b-input>
                </b-field>
                <b-field :label="$t('profile.surname')">
                  <b-input type="text" v-model="edit_user.lastname"></b-input>
                </b-field>
                <b-field :label="$t('profile.mail')">
                  <b-input type="text" v-model="edit_user.u"></b-input>
                </b-field>
                <b-field :label="$t('add-member.language')">
                  <b-select :value="edit_user.lang" v-model="edit_user.lang">
                    <option value="de">{{ $t('add-member.german') }}</option>
                    <option value="en">{{ $t('add-member.english') }}</option>
                  </b-select>
                </b-field>
              </section>
              <footer class="modal-card-foot">
                <Button @click="change_user()" :text="$t('common.save')" />
                <Button @click="toggle_user_modal()" :text="$t('common.cancel')" />
              </footer>
            </div>
          </div>
          <span v-if="rights.assignment">
            <h1 class="heading">
              <strong>{{ $t('groups.assignments') }}</strong>
              <Button class="add-group" @click="add_assignment"
              :text="$t('groups.new-assignment')" :type="'zoom'" />
            </h1>
          </span>
          <b-table v-if="rights.assignment"
          class="table"
          :data="assignments"
          :paginated="isPaginated"
          :per-page="perPage"
          :current-page.sync="currentPage2"
          :pagination-simple="isPaginationSimple"
          :pagination-position="paginationPosition"
          :default-sort-direction="defaultSortDirection"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          :selected.sync="selected_assignment"
          :hoverable="true"
          @dblclick="open_assignment($event)"
          default-sort="lastname"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page">
            <b-table-column searchable field="name" :label="$t('groups.name')" sortable v-slot="props">
              {{ props.row.name }}
            </b-table-column>
            <b-table-column searchable field="name" :label="$t('groups.status')" sortable v-slot="props">
              {{ props.row.status }}
            </b-table-column>
            <b-table-column v-slot="props">
              <router-link :to="{name: 'Stats', params: {assignment_id: props.row.id}}">
                <Button :text="$t('groups.statistics')" :type="'hasIcon'" class="background-color-client"
                  :icon="['fas', 'chart-bar']"/>
              </router-link>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </section>
    <Footer />
  </section>
</template>
<script>
/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */

import Button from '@/traincom/components/Common/Button.vue';
import AddClient from '../components/Clients/AddClient.vue';
import AddGroup from '../components/Clients/AddGroup.vue';
import AddMember from '../components/Clients/AddMember.vue';
import Navbar from '../../components/Common/Navbar.vue';
import Footer from '../../components/Common/Footer.vue';

export default {
  components: {
    Navbar,
    Footer,
    AddClient,
    AddGroup,
    AddMember,
    Button,
  },

  created() {
    this.$store.dispatch('Clients/load');
    if (this.groupSelected) {
      this.$store.dispatch('Groups/load_group', this.group_id);
    }
  },

  beforeDestroy() {
    // this.change_client_selected(false);
    // this.groupSelected = false;
  },

  data() {
    return {
      selected_group: null,
      selected_assignment: null,
      selected_user: null,
      groupsa: null,
      member: null,
      memberOf: null,
      name_modal: false,
      to_change_group_name: '',
      to_change_group_id: '',
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage1: 1,
      currentPage2: 1,
      perPage: 10,
      user_modal: false,
      edit_user: {
        old_u: '',
        u: '',
        firstname: '',
        lastname: '',
        attention: '',
        lang: '',
      },
    };
  },

  computed: {
    clients() {
      return this.$store.getters['Clients/get_clients'];
    },
    groups() {
      return this.$store.getters['Groups/get_groups'];
    },
    members() {
      return this.$store.getters['Groups/get_members'];
    },
    assignments() {
      return this.$store.getters['Groups/get_assignments'];
    },
    group_id() {
      return this.$store.getters['Groups/get_sg_id'];
    },
    rights() {
      return this.$store.getters['User/rights'];
    },
    groupSelected() {
      return this.$store.getters['Groups/get_group_selected'];
    },
    clientSelected() {
      return this.$store.getters['Groups/get_client_selected'];
    },
    selectedClient() {
      return this.$store.getters['Groups/get_selected_client'];
    },
    currentMenu() {
      return this.$store.getters['Groups/get_current_menu'];
    },
    editClient() {
      return this.$store.getters['Groups/get_edit_client'];
    },
  },

  methods: {
    change_group_selected(value) {
      this.$store.dispatch('Groups/change_group_selected', value);
    },
    change_client_selected(value) {
      this.$store.dispatch('Groups/change_client_selected', value);
    },
    import_user() {
      this.$store.dispatch('User/import_user');
    },
    change_group(group) {
      this.$store.dispatch('Groups/load_group', group.id);
      this.change_group_selected(true);
    },
    toggle_name_modal(group) {
      if (group) {
        this.to_change_group_name = group.name;
        this.to_change_group_id = group.id;
      }
      this.name_modal = !this.name_modal;
    },
    save_name() {
      const params = {
        name: this.to_change_group_name,
        groupID: this.to_change_group_id,
      };
      this.$store.dispatch('Groups/changename', params).then((r) => {
        if (r) {
          this.$buefy.toast.open({ message: this.$t('groups.group-changed'), type: 'is-success' });
          this.$store.dispatch('Groups/load', this.clientId);
          this.$store.dispatch('Clients/load');
          this.change_client(this.selectedClient.name, this.selectedClient.id, this.currentMenu.index);
          this.change_group(this.$store.getters['Groups/get_sg_id']);
          this.toggle_name_modal();
          return;
        }
        this.$buefy.toast.open({ message: this.$t('groups.failed'), type: 'is-danger' });
      });
    },
    toggle_user_modal(user) {
      if (user) {
        this.edit_user.old_u = user.u;
        this.edit_user.u = user.u;
        this.edit_user.firstname = user.firstname;
        this.edit_user.lastname = user.lastname;
        this.edit_user.attention = user.attention;
        if (user.lang) {
          this.edit_user.lang = user.lang;
        } else {
          this.edit_user.lang = 'de';
        }
      }
      this.user_modal = !this.user_modal;
    },
    change_user() {
      this.$store.dispatch('Groups/change_user', this.edit_user).then((r) => {
        if (r) {
          this.$buefy.toast.open({ message: this.$t('groups.user-changed'), type: 'is-success' });
          this.$store.dispatch('Groups/load_group', this.group_id);
          this.toggle_user_modal();
          return;
        }
        this.$buefy.toast.open({ message: this.$t('groups.failed'), type: 'is-danger' });
      });
    },
    open_assignment(assignment) {
      this.$router.push({ name: 'Assignment', params: { id: assignment.id } });
    },
    change_client(name1, clientId, index) {
      this.$store.dispatch('Groups/load', clientId);
      this.change_client_selected(true);
      const payload = {
        client: {
          name: name1,
          id: clientId,
        },
        currentMenu: {
          id: index,
          title: name1,
        },
        editClient: index,
      };
      this.$store.dispatch('Groups/change_selected_client', payload);
      this.change_group_selected(false);
    },
    add_client(payload) {
      this.$store.commit('Clients/add_client', payload);
    },
    add_group(name) {
      this.$store.commit('Groups/add_group', { p: name });
    },
    add_assignment() {
      this.$store.commit('Assignment/new_assignment', this.group_id);
      this.$store.commit('Assignment/set_loading', true);
      this.$router.push('assignment/new');
    },
    del_member(payload) {
      const delMember = {
        member: payload,
        group: this.group_id,
        del_assignments: false,
      };
      this.$buefy.dialog.confirm({
        title: this.$t('groups.del-member'),
        message: this.$t('groups.del-member-text'),
        cancelText: this.$t('common.cancel'),
        confirmText: this.$t('groups.del-member'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$buefy.dialog.confirm({
            title: this.$t('groups.del-assignments'),
            message: this.$t('groups.del-assignments-text'),
            cancelText: this.$t('common.no'),
            confirmText: this.$t('common.yes'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
              delMember.del_assignments = true;
              this.$store.dispatch('Groups/del_member', delMember).then(() => {
                this.$buefy.toast.open({ message: this.$t('groups.del-member-success'), type: 'is-success' });
              });
            },
            onCancel: () => {
              this.$store.dispatch('Groups/del_member', delMember).then(() => {
                this.$buefy.toast.open({ message: this.$t('groups.del-member-success'), type: 'is-success' });
              });
            },
          });
        },
      });
    },
    del_group(payload) {
      this.$buefy.dialog.confirm({
        title: this.$t('groups.del-group'),
        message: this.$t('groups.del-group-text'),
        cancelText: this.$t('common.cancel'),
        confirmText: this.$t('groups.del-group'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch('Groups/del_group', {
            group: this.group_id,
            name: payload.name,
          });
          this.change_group_selected(false);
          this.$buefy.toast.open({ message: this.$t('groups.del-group-success'), type: 'is-success' });
        },
      });
    },
  },
};
</script>

<style lang="scss">
.groups {
  .hero {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 450;
    margin-bottom: 1rem;
  }

  .admin-title {
    display: inline;
    text-transform: uppercase;
    font-size: 3em;
    padding-top: 2rem;
  }

  .card {
    width: 50%;
    padding: 4rem;
  }

  .table {
    Button {
      font-size: .9rem;
      height: 2.2rem;
      padding: .6rem;
    }
    tr.is-selected {
      background-color: var(--secondary-color)!important;
    }
    tr {
      cursor: pointer;
    }
  }

  .icon-frame {
    padding: .2rem .5rem;
    cursor: pointer;
  }

  .options-dropdown {
    color: black !important;
  }

  .options-dropdown-delete {
    background-color: #f14668 !important;
    border-color: transparent !important;
    color: #fff !important;
  }

  .client {
    display: inline-flex;
  }

  .table {
    padding-bottom: 2rem;
  }

  .dropdown-title {
    margin: .5rem;
    font-weight: 600;
  }

  .dropdown-button {
    margin-right: 1rem;
    font-size: 1.4rem;
  }

  .dropdown-list {
    font-size: 1.4rem;
  }

  .heading {
    font-size: 2rem;
  }

  .edit-section {
    margin-top: 2rem;
    min-height: 20rem;
  }

  .add-group {
    margin-left: 1rem !important;
    display: inline;
  }

  .fat-text {
    font-family: "Roboto";
    font-weight: 900;
  }

  .background-color-client {
    background-color: var(--secondary-color);
    color: var(--secondary-color-text);
  }
}
</style>
