<!-- eslint-disable max-len -->

<template>
  <section class="assignment fixed-nav" v-if="!loading">
    <Navbar />
    <div class="responsive-pad">
    <section class="hero is-small">
      <div class="">
        <h1 class="admin-title">{{ $t('assignment.title-thin') }}</h1><h1 class="admin-title fat-text"> {{ $t('assignment.title-bold') }}</h1>
      </div>
    </section>
    <Button :text="$t('assignment.back-button')" :type="'hasIcon'" :icon="['fa', 'arrow-left']"
    @click="$router.push({ path: '/groups' })" class="back-button" />
    <section class="columns">
      <div class="column is-two-third">
        <div class="narrow">
          <b-field :label="$t('assignment.name')">
            <input class="input" v-model="assignment.name" type="text" :placeholder="$t('assignment.name')">
          </b-field>
           <b-field :label="$t('assignment.description')">
            <textarea class="textarea is-small" v-model="assignment.desc"></textarea>
          </b-field>
          <b-field :label="$t('assignment.dropdown')">
            <b-select :disabled="disable_train_selec" @input="selectCourse"
            :placeholder="$t('assignment.dropdown-placeholder')" v-model="assignment.course_id">
              <option v-for="(course, i) in courses" :value="course.id" :key="i">
                {{ course.name }}
              </option>
            </b-select>
          </b-field>
          <b-field :label="$t('assignment.previous-training')">
            <b-select :disabled="disable_train_selec"
            :placeholder="$t('assignment.previous-training-placeholder')" v-model="assignment.required_assignment">
              <option value="">{{ $t('assignment.title-thin') }}</option>
              <option v-for="(assignment, i) in assignments" :value="assignment.id" :key="i">
                {{ assignment.name }}
              </option>
            </b-select>
          </b-field>
          <b-field :label="$t('assignment.unlock')" class="datepicker">
            <b-datepicker
            v-model="fd"
            type="day"
            icon="calendar-today"
            :date-formatter="formatter"
            :date-parser="parser"
            trap-focus>
            </b-datepicker>
          </b-field>
          <b-field :label="$t('assignment.logo-upload')">
            <Upload class="upload" v-model="assignment_img"
            @input="upload_assignment_img($event, payload)"
            :mimetype="'image/*'" :multi="false" style="width: 100%;" />
          </b-field>
          <Button :text="'Logo löschen'" v-if="this.assignment.logo_l"
          @click="del_logo()"/>
          <img height="200px !important" :src="assignment.logo_l">
        </div>
      </div>
      <div class="column is-one-third narrow">
        <div>
          <h2 class="status">{{ $t('assignment.status') }}: {{assignment.status}}</h2>
        </div>
        <div class="button-margin">
          <div>
            <Button class="max-width" @click="save()" :text="$t('common.save')"
            :disabled="assignment.course_id === ''" />
          </div>
          <div>
            <Button class="max-width" @click="start_assignment()" :text="$t('assignment.assign-and-activate')"
            :type="'zoom'"
            :disabled="assignment.status === ('running' || 'deleted' || 'ended')"/>
          </div>
          <div>
            <Button class="max-width" @click="add_assignment()" :text="$t('assignment.assign')"
            :type="'zoom'"
            :disabled="this.a_status.includes(assignment.status)"/>
          </div>
          <div>
            <Button class="max-width" :text="$t('assignment.stop')" :type="'zoom'"
            @click="stop_assignment()"
            :disabled="assignment.status !== ('running')"/>
          </div>
          <div>
            <Button class="max-width" :text="$t('common.delete')" :type="'zoom'"
            @click="delete_assignment()" :disabled="disable_del"/>
          </div>
          <div>
            <Button class="max-width" :text="$t('common.end')" :type="'zoom'"
            @click="end_assignment()" :disabled="assignment.status !== ('running')"/>
          </div>
        </div>
      </div>
    </section>
    <hr>
    <h2 class="status narrow">{{ $t('assignment.mail-messages') }}</h2>
    <section class="columns reminder-section">
      <div class="column is-two-third">
        <div class="narrow">
          <b-field :label="$t('profile.attention')">
            <b-select :placeholder="$t('profile.attention')" v-model="assignment.attention">
              <option value="0">
                Sehr geehrte*r
              </option>
              <option value="1">
                Hallo
              </option>
              <option value="2">
                Guten Tag
              </option>
              <option value="3">
                Hello
              </option>
              <option value="4">
                Dear
              </option>
            </b-select>
          </b-field>
          <b-field :label="$t('assignment.sender-name')" class="inline">
            <input type="text" class="input" v-model="assignment.sendername">
          </b-field>
          <b-field :label="$t('assignment.sender-mail')" class="inline">
            <input type="text" class="input" v-model="assignment.senderemail">
          </b-field>
          <b-field :label="$t('assignment.first-mail-text')">
            <textarea class="textarea is-small" v-model="assignment.firstmail"></textarea>
          </b-field>
          <b-field v-if="course_type === 'training'" :label="$t('assignment.training-mail-text')">
            <textarea class="textarea is-small" v-model="assignment.followmail"></textarea>
          </b-field>
          <b-field :label="$t('assignment.anchor-mail-text')">
            <textarea class="textarea is-small" v-model="assignment.anchormail"></textarea>
          </b-field>
          <b-field :label="$t('assignment.last-mail-text')">
            <textarea class="textarea is-small" v-model="assignment.lastmail"></textarea>
          </b-field>
        </div>
      </div>
      <div class="column is-one-third narrow">
        <b-field :label="$t('assignment.reminder-frequency')">
          <input class="input" type="number" v-model.number="assignment.frequency">
        </b-field>
        <b-field class="list">
          <b-switch v-model="assignment.inc_sat">
            {{ $t('assignment.including-saturday') }}
          </b-switch>
        </b-field>
        <b-field class="list">
          <b-switch v-model="assignment.inc_sun">
            {{ $t('assignment.including-sunday') }}
          </b-switch>
        </b-field>
        <hr>
        <b-field class="list">
          <b-switch v-model="assignment.canchange">
            {{ $t('assignment.changeable') }}
          </b-switch>
        </b-field>
        <b-field class="list">
          <b-switch v-model="assignment.canstop">
            {{ $t('assignment.stoppable') }}
          </b-switch>
        </b-field>
        <b-field class="list">
          <b-switch v-model="assignment.canrate">
            {{ $t('assignment.rateable') }}
          </b-switch>
        </b-field>
        <b-field :label="$t('assignment.max-days')">
          <input class="input" type="number" v-model.number="assignment.maxdays">
        </b-field>
        <Button :disabled="this.$route.params.id === 'new'"
        :text="$t('assignment.change-all')" @click="edit_reminder('all')" />
      </div>
    </section>
    <h2 class="status narrow">{{ $t('assignment.weekly-report') }}</h2>
    <section class="report">
      <div class="column is-two-third">
        <div class="narrow">
          <b-field class="list">
            <b-switch v-model="assignment.autoreport">
              {{ $t('assignment.weekly-report') }}
            </b-switch>
          </b-field>
        </div>
      </div>
    </section>
    </div>
    <Footer />
  </section>
</template>

<script>

import Upload from '@/traincom/components/Common/Upload.vue';
import Button from '@/traincom/components/Common/Button.vue';
import Navbar from '../../components/Common/Navbar.vue';
import Footer from '../../components/Common/Footer.vue';

export default {
  components: {
    Navbar,
    Footer,
    Upload,
    Button,
  },

  data() {
    return {
      assignment_img: {},
      a_status: ['assigned', 'deleted', 'running', 'stopped', 'ended'],
    };
  },

  created() {
    console.log(this.$route.params.id);
    if (this.$route.params.id !== 'new') {
      this.$store.dispatch('Assignment/load', this.$route.params.id);
    }
    this.$store.dispatch('Courses/load_acc_courses');
    this.$store.dispatch('Account/load');
  },

  computed: {
    fd: {
      get() {
        if (this.assignment.earliest_start !== '') {
          return new Date(this.assignment.earliest_start);
        }
        return new Date();
      },
      set(newValue) {
        this.assignment.earliest_start = newValue;
      },
    },
    assignment() {
      return this.$store.getters['Assignment/get_assignment'];
    },
    courses() {
      const courses = this.$store.getters['Courses/acc_courses'];
      return courses;
    },
    assignments() {
      return this.$store.getters['Groups/get_sg'].assigned;
    },
    loading() {
      return this.$store.getters['Assignments/is_loading'];
    },
    course_type() {
      const courses = this.$store.getters['Courses/acc_courses'];
      for (let i = 0; i < courses.length; i += 1) {
        if (courses[i].id === this.assignment.course_id) return courses[i].type;
      }
      return false;
    },
    disable_del() {
      if ((this.$route.params.id === 'new') && (this.assignment.status === 'deleted')) return true;
      return false;
    },
    disable_train_selec() {
      if ((this.assignment.status === 'assigned') || (this.assignment.status === 'running')) return true;
      if (this.assignment.status === 'deleted') return true;
      return false;
    },
  },

  methods: {
    formatter(d) {
      return d.toLocaleDateString();
    },
    parser(d) {
      console.log(d);
      const nd = new Date(d);
      console.log(nd);
      return nd;
    },
    selectCourse() {
      this.typeofcourse = this.course_type;
    },
    start_assignment() {
      this.$store.dispatch('Assignment/start_assignment').then(() => {
        this.$router.replace({ path: `/assignment/${this.assignment.id}` });
      });
    },
    add_assignment() {
      this.$store.dispatch('Assignment/add_assignment').then(() => {
        this.$router.replace({ path: `/assignment/${this.assignment.id}` });
      });
    },
    stop_assignment() {
      this.$store.dispatch('Assignment/stop_assignment');
    },
    async upload_assignment_img(assignmentImg) {
      const res = await this.$store.dispatch('Uploads/upload_assignment_img', assignmentImg);
      this.assignment.logo_l = res.data;
    },
    del_logo() {
      this.assignment.logo_l = '';
    },
    edit_reminder(type) {
      this.$store.dispatch('Assignment/edit_reminder', type).then((response) => {
        if (response) {
          this.$buefy.toast.open({
            message: this.$t('assignment.change-success'),
            type: 'is-success',
          });
        } else {
          this.$buefy.toast.open({
            message: this.$t('assignment.change-error'),
            type: 'is-danger',
          });
        }
      });
    },
    delete_assignment() {
      this.$buefy.dialog.confirm({
        title: this.$t('assignment.del-assignment'),
        message: this.$t('assignment.del-assignment-text'),
        confirmText: this.$t('common.delete'),
        cancelText: this.$t('assignment.cancel'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch('Assignment/delete_assignment').then((response) => {
            if (response) {
              this.$buefy.toast.open({
                message: this.$t('assignment.del-assignment-success'),
                type: 'is-success',
              });
              this.$router.push({ name: 'Groups' });
            }
          });
        },
      });
    },
    end_assignment() {
      this.$buefy.dialog.confirm({
        title: this.$t('assignment.end-assignment'),
        message: this.$t('assignment.end-assignment-text'),
        confirmText: this.$t('common.end'),
        cancelText: this.$t('common.cancel'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch('Assignment/end_assignment').then((response) => {
            if (response) {
              this.$buefy.toast.open({
                message: this.$t('assignment.end-assignment-success'),
                type: 'is-success',
              });
              this.save();
            }
          });
        },
      });
    },
    save() {
      this.$store.dispatch('Assignment/save').then((response) => {
        this.$router.replace({ path: `/assignment/${this.assignment.id}` });
        this.saved = true;
        if (response) {
          this.$buefy.toast.open({
            message: this.$t('assignment.save-assignment-success'),
            type: 'is-success',
          });
          this.$emit('change');
          this.toggleModal();
        } else {
          this.$buefy.toast.open({
            message: this.$t('assignment.save-assignment-error'),
            position: 'is-bottom',
            type: 'is-danger',
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.assignment {
  .fat-text {
    font-weight: 900;
  }
  .hero {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .admin-title {
    display: inline;
    text-transform: uppercase;
    font-size: 3em;
  }

  textarea {
    font-size: 1rem;
    font-family: "Noto Sans";
  }

  .columns {
    margin-top: 1rem;
  }

  .button-margin {
    margin-top: 3rem;
  }

  .status {
    font-family: "Noto Sans";
    font-weight: 600;
    font-size: 1.4rem;
  }

  .reminder-section {
    margin-top: 3rem;
  }

  .report {
    margin-top: 3rem;
  }

  .b-checkbox.checkbox input[type=checkbox] + .check {
    border-color: var(--primary-color);
    background: var(--primary-color);
  }

  .background-color-client {
    background-color: var(--secondary-color);
    color: var(--secondary-color-text);
  }

  .datepicker {
    margin-top: 1rem;
    .has-text-primary {
      color: var(--primary-color) !important;
    }
    .select:not(.is-multiple):not(.is-loading)::after {
      border-color: var(--primary-color) !important;
    }
    .datepicker-table .datepicker-body {
      .datepicker-cell.is-selected {
        background-color: var(--primary-color) !important;
      }
      .datepicker-cell.is-today {
        border-color: var(--primary-color) !important;
      }
    }
    .select select:focus {
      border-color: var(--primary-color) !important;
      box-shadow: none;
    }
  }

  .list {
    display: table;
  }

  .max-width {
    margin-top: 1rem;
    width: 100%;
  }
}
</style>
