<template>
  <section class="blank-text">
    <div v-for="(sentence, s_index) in page.sentences" :key="sentence.id" class="notification">
      <span v-if="editmode" @click="del_page()" class="del">
        <font-awesome-icon class="icon del-page red" :icon="['fas', 'trash']" />
      </span>
      <div>
        <span v-for="(part, p_index) in sentence.parts" :key="part.id">
          <span v-if="part.type === 'text'" class="content part">
            <span v-if="!editmode"> {{ part.content }} </span>
            <input v-if="editmode" type="text" class="input input-text"
            v-model="part.content" placeholder="Text Feld">
          </span>
          <span v-if="part.type === 'blank'" class="part">
            <input v-if="!editmode" type="text" v-model="part.answer"
              class="input is-small" :class="color(part)" :disabled="checked">
             <input v-if="editmode" type="text" v-model="part.content"
              class="input">
          </span>
          <span v-if="part.type === 'mc_blank'" class="part">
            <b-select v-if="!editmode" placeholder="select an answer">
              <option v-for="option in part.options" :value="option" :key="option.id">
                {{ option.o }}
              </option>
            </b-select>
            <span v-if="editmode" class="dropdown-edit">
              <span>
                <ul>
                  <li v-for="(option, o_index) in part.options" :key="option.id"
                  style="display: inline">
                    <b-checkbox v-model="option.a">
                    {{ option.o }}
                    </b-checkbox>
                    <span v-if="editmode" @click="del_option(s_index, p_index, o_index)">
                      <font-awesome-icon class="icon red" :icon="['fas', 'trash']" />
                    </span>
                  </li>
                </ul>
              </span>
              <input class="input" @keyup.enter="add_option(s_index, p_index)" v-model="edit_input"
              placeholder="ENTER">
            </span>
          </span>
          <span v-if="editmode" @click="del_part(s_index, p_index)">
            <font-awesome-icon class="icon red" :icon="['fas', 'trash']" />
          </span>
        </span>
      </div>
      <button @click="add_text(s_index)" v-if="editmode" class="button">Add Text</button>
      <button @click="add_blank(s_index)" v-if="editmode" class="button">Add Blank</button>
      <button @click="add_MC(s_index)" v-if="editmode" class="button">Add Multiple Choice</button>
      <b-field v-if="editmode">
        <b-switch v-model="page.note_activated">
          {{ $t('course.deactivate-notes') }}
        </b-switch>
      </b-field>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      answers: '',
      checked: false,
      edit_input: '',
    };
  },

  props: ['page', 'editmode'],

  methods: {
    check() {
      this.checked = true;
      console.log('checked');
    },

    add_text(i) {
      const text = { type: 'text', content: '' };
      this.page.sentences[i].parts.push(text);
    },

    add_blank(i) {
      const blank = { type: 'blank', content: '', answer: '' };
      this.page.sentences[i].parts.push(blank);
    },

    add_MC(i) {
      const blank = {
        type: 'mc_blank', options: [], content: '', answer: '',
      };
      this.page.sentences[i].parts.push(blank);
    },

    del_part(si, pi) {
      this.page.sentences[si].parts.splice(pi, 1);
    },

    del_page() {
      this.$emit('del_page');
    },

    add_option(si, pi) {
      const option = {
        o: this.edit_input, a: false, u: false,
      };
      this.page.sentences[si].parts[pi].options.push(option);
      this.edit_input = '';
    },

    del_option(si, pi, oi) {
      this.page.sentences[si].parts[pi].options.splice(oi, 1);
    },

    color(part) {
      if (this.checked) {
        console.log('changing color');
        if (part.content === part.answer) {
          console.log('green color');
          return 'glow-green';
        }
        return 'glow-red';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" theme="a" scoped>
.blank-text {
  color: black;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 1.2rem;

  .button {
    font-weight: 600;
    margin-top: 1rem;
  }

  .notification {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    height: 100%;
    background: white;
  }

  .part {
    display: inline-block;
  }

  .icon {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    transition: all .3s;
    :hover {
      cursor: pointer;
      transform: scale(105%);
      transition: all .3s;
    }
  }

  .input {
    width: 10rem;
    margin-bottom: 1rem;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 1.2rem;
  }

  .input-text {
    width: auto;
    background-color: transparent;
    border: none;
    border-bottom: solid black .5px;
    border-radius: 0;
    box-shadow: none;
  }

  .glow-red {
    color: #C0564A;
    border-radius: 10px;
    text-shadow: 1px 1px 3px #C0564A;
  }

  .glow-green {
    color: #00A237;
    border-radius: 10px;
    text-shadow: 1px 1px 3px #00A237;
  }

  .red {
    color: red;
  }

  .del-page {
    margin: 0;
    font-size: 2rem;
  }

  .del {
    float: right;
  }

}

</style>
