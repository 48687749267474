<template>
  <section>
    <div v-for="(page, index) in unit.pages" :key="page.id">
      <div class="mt-5" v-if="page.type === 'check'">
        <Check v-bind:editmode="editmode" v-bind:page="get_page(index)" :key="page.id"
        v-on:del_page="del_page(index)"/>
      </div>
      <div class="mt-5" v-if="page.type === 'btext'">
        <BlankText v-bind:editmode="editmode" v-bind:page="get_page(index)" :key="page.id"
        v-on:del_page="del_page(index)"/>
      </div>
      <div class="mt-5" v-if="page.type === 'questions'">
        <Questions v-bind:editmode="editmode" v-bind:page="get_page(index)" :key="page.id"
        v-on:del_page="del_page(index)"/>
      </div>
      <div class="mt-5" v-if="page.type === 'pquestion'">
        <Plainq v-bind:editmode="editmode" v-bind:page="get_page(index)" :key="page.id"
        v-on:del_page="del_page(index)"/>
      </div>
      <div class="mt-5" v-if="page.type === 'video'">
        <Cvid v-bind:editmode="editmode" v-bind:page="get_page(index)" :key="page.id"
        v-on:del_page="del_page(index)"/>
      </div>
      <div class="mt-5" v-if="page.type === 'pdf'">
        <PDF v-bind:editmode="editmode" v-bind:page="get_page(index)" :key="page.id"
        v-on:del_page="del_page(index)"/>
      </div>
      <div class="mt-5" v-if="page.type === 'justtext'">
        <JustText v-bind:editmode="editmode" v-bind:page="get_page(index)" :key="page.id"
        v-on:del_page="del_page(index)"/>
      </div>
      <div class="mt-5" v-if="page.type === 'pw'">
        <PayWall v-bind:editmode="editmode" v-bind:page="get_page(index)" :key="page.id"
        v-on:del_page="del_page(index)"/>
      </div>
      <div class="mt-5" v-if="page.type === 'pairs'">
        <Pairs v-bind:editmode="editmode" v-bind:page="get_page(index)" :key="page.id"
        v-on:del_page="del_page(index)"/>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable no-restricted-globals */
import Check from '@/traincom/components/pagetypes/Check.vue';
import Cvid from '@/traincom/components/pagetypes/Cvid.vue';
import PDF from '@/traincom/components/pagetypes/PDF.vue';
import JustText from '@/traincom/components/pagetypes/JustText.vue';
import PayWall from '@/traincom/components/pagetypes/PayWall.vue';
import Questions from './Questions.vue';
import BlankText from './BlankText.vue';
import Plainq from './Plainq.vue';
import Pairs from './Pairs.vue';

export default {
  components: {
    Questions,
    BlankText,
    PDF,
    JustText,
    PayWall,
    Plainq,
    Cvid,
    Pairs,
    Check,
  },

  props: ['unit', 'editmode'],

  methods: {
    get_page(i) {
      return this.$store.getters['Course/get_page'](i);
    },
    add_page() {
      return this.$store.commit('Course/add_page');
    },
    del_page(index) {
      this.$store.commit('Course/del_page', index);
    },
  },

  computed: {
    curr_unit() {
      return this.$store.getters['Course/curr_unit'];
    },
  },

};
</script>

<style lang="scss" scoped>

</style>
