<!-- eslint-disable max-len -->

<template>
  <section>
    <Navbar />
    <div class="responsive-pad fixed-nav">
      <section class="hero is-small">
        <div class="">
          <h1 class="account-title">{{ $t('ex-accounts.title-thin') }}</h1> <h1 class="account-title fat-text"> {{ $t('ex-accounts.title-bold') }}</h1>
        </div>
      </section>
      <section class="ex-accounts content columns">
        <div class="column">
          <span>
            <h1 class="heading">
              <strong>{{ $t('ex-accounts.accounts') }}</strong>
              <Button @click="toggleAccModal" :text="$t('ex-accounts.new-account')" :type="'zoom'"/>
            </h1>
            <div class="modal is-active" v-if="accModal">
              <div class="modal-background" @click="toggleAccModal"></div>
              <div class="modal-card">
                <header class="modal-card-head">
                  <p class="modal-card-title">{{ $t('ex-accounts.add-account') }}</p>
                  <button class="delete" @click="toggleAccModal" aria-label="close"></button>
                </header>
                <section class="modal-card-body">
                  <div>
                    <b-field :label="$t('ex-accounts.account-name')">
                      <b-input type="text" :placeholder="$t('ex-accounts.name')"
                      v-model="newAccount.name">
                      </b-input>
                    </b-field>
                    <h2 class="heading-add">
                      <strong>{{ $t('ex-accounts.admin') }}</strong>
                    </h2>
                    <div>
                      <b-field :label="$t('profile.attention')">
                        <b-input v-model="newAccount.admin.attention" :placeholder="$t('profile.attention')">

                        </b-input>
                      </b-field>
                      <b-field :label="$t('profile.name')">
                        <b-input v-model="newAccount.admin.firstname" :placeholder="$t('profile.name')">

                        </b-input>
                      </b-field>
                      <b-field :label="$t('profile.surname')">
                        <b-input v-model="newAccount.admin.lastname" :placeholder="$t('profile.surname')">
                        </b-input>
                      </b-field>
                      <b-field :label="$t('profile.mail')">
                        <b-input type="email" required v-model="newAccount.admin.u"
                        :validation-message="$t('ex-accounts.mail-validation')"
                        :placeholder="$t('profile.mail')">
                        </b-input>
                      </b-field>
                      <b-field :label="$t('ex-accounts.initial-password')">
                        <b-input type="text" required v-model="newAccount.admin.p"
                        :placeholder="$t('ex-accounts.password-placeholder')">
                        </b-input>
                      </b-field>
                    </div>
                  </div>
                </section>
                <footer class="modal-card-foot">
                  <Button :text="$t('common.cancel')" :type="'zoom'"
                  @click="toggleAccModal" />
                  <Button :text="$t('ex-accounts.add-account')" :type="'zoom'"
                  @click="addAccount()" :disabled="required"/>
                </footer>
              </div>
            </div>
          </span>
          <b-table
            class="table is-half"
            :data="exAccounts"
            :selected.sync="selectedAccount"
            :custom-is-selected="(a, b) => { return a.id === b.id }"
            @click="changeAccount($event)"
            default-sort="name"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page">
            <b-table-column searchable field="name" :label="$t('ex-accounts.name')" sortable v-slot="props">
              {{ props.row.name }}
            </b-table-column>
          </b-table>
        </div>
        <div class="column">
          <section>
            <b-tabs v-model="activeTab" position="" class="tab tab-custom ex-tabs">
              <b-tab-item :label="$t('ex-accounts.admins')">
                <b-table
                  class="table is-half"
                  :data="selectedAccount.members"
                  default-sort="name"
                  aria-next-label="Next page"
                  aria-previous-label="Previous page"
                  aria-page-label="Page"
                  aria-current-label="Current page">
                  <b-table-column searchable field="fullQName" :label="$t('ex-accounts.name')" sortable v-slot="props">
                    {{ props.row.fullQName }}
                  </b-table-column>
                </b-table>
              </b-tab-item>
              <b-tab-item :label="$t('ex-accounts.trainings')">
                <b-table
                  v-if="!loading"
                  checkable
                  class="table is-half"
                  :data="courses"
                  :checked-rows.sync="checkedRows"
                  :custom-is-checked="(a, b) => { return a.id === b.id }"
                  default-sort="name"
                  aria-next-label="Next page"
                  aria-previous-label="Previous page"
                  aria-page-label="Page"
                  aria-current-label="Current page">
                  <b-table-column searchable field="name" :label="$t('ex-accounts.name')" sortable v-slot="props">
                    {{ props.row.name }}
                  </b-table-column>
                </b-table>
                <Button :text="$t('common.change')" @click="updateAccess()"/>
              </b-tab-item>
              <b-tab-item :label="$t('ex-accounts.sendings')">
                <b-field :label="$t('ex-accounts.select-date')" class="datepicker">
                  <b-datepicker
                  v-model="date"
                  @input="loadMonthCourses"
                  type="month"
                  icon="calendar-today"
                  trap-focus>
                  </b-datepicker>
                </b-field>
              </b-tab-item>
            </b-tabs>
          </section>
        </div>
      </section>
    </div>
    <Footer />
  </section>
</template>

<script>
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import formatting from '@/traincom/mixins/formatting';
import Button from '@/traincom/components/Common/Button.vue';
import Navbar from '../../components/Common/Navbar.vue';
import Footer from '../../components/Common/Footer.vue';

export default {
  components: {
    Button,
    Navbar,
    Footer,
  },
  created() {
    this.$store.dispatch('Courses/load_acc_courses');
    this.$store.dispatch('Account/load_ex_accounts');
  },
  mixins: [formatting],
  data() {
    return {
      selected: false,
      date: null,
      activeTab: 0,
      index: 0,
      accModal: false,
      checkedRows: [],
      newAccount: {
        name: '',
        admin: {
          is_admin: true,
          attention: '',
          firstname: '',
          lastname: '',
          u: '',
          p: '',
        },
      },
    };
  },
  computed: {
    required() {
      if (this.newAccount.admin.attention !== '' && this.newAccount.admin.firstname !== '' && this.newAccount.admin.lastname !== '' && this.newAccount.admin.p !== '' && this.newAccount.name !== '') {
        if (this.mail_validation(this.newAccount.admin.u)) {
          return false;
        }
      }
      return true;
    },
    currentMenu() {
      return this.$store.getters['Account/current_menu'];
    },
    selectedDate() {
      return this.dateFormat(this.date);
    },
    monthCourses() {
      return this.$store.getters['Account/monthCourses'];
    },
    exAccounts() {
      return this.$store.getters['Account/ex_accounts'];
    },
    courses() {
      if (!this.selected) return [];
      return this.$store.getters['Courses/acc_courses_ids'];
    },
    selectedAccount() {
      return this.$store.getters['Account/selected_ex_account'];
    },
    loading() {
      return this.$store.getters['Account/loading'];
    },
  },
  methods: {
    addAccount() {
      this.$store.dispatch('Account/add_account', this.newAccount).then((response) => {
        if (response) {
          this.$store.dispatch('Account/load_ex_accounts');
          this.toggleAccModal();
          this.$buefy.toast.open({
            message: this.$t('ex-accounts.add-account-success'),
            type: 'is-success',
          });
        } else {
          this.$buefy.toast.open({
            message: this.$t('ex-accounts.add-account-error'),
            type: 'is-danger',
          });
        }
      });
    },
    loadMonthCourses() {
      this.$store.dispatch('Account/loadMonthCourses', this.selectedDate);
    },
    toggleAccModal() {
      this.accModal = !this.accModal;
    },
    changeAccount(acc) {
      this.checkedRows = acc.access;
      this.selected = true;
      this.$store.commit('Account/change_selected_acc', acc);
    },
    updateAccess() {
      this.$store.dispatch('Account/editAccess', this.checkedRows).then((response) => {
        if (response) {
          this.$store.dispatch('Account/load_ex_accounts');
          this.$buefy.toast.open({
            message: this.$t('ex-accounts.changes-success'),
            type: 'is-success',
          });
        } else {
          this.$buefy.toast.open({
            message: this.$t('ex-accounts.changes-error'),
            type: 'is-danger',
          });
        }
      });
    },
    change_rights(userId1, rights1) {
      const payload = {
        userId: userId1,
        rights: rights1,
        accId: this.exAccounts[this.index].id,
      };
      this.$store.dispatch('Account/change_rights', payload).then((response) => {
        if (response) {
          this.$buefy.toast.open({
            message: this.$t('ex-accounts.add-admin-success'),
            type: 'is-success',
          });
        } else {
          this.$buefy.toast.open({
            message: this.$t('ex-accounts.add-admin-error'),
            type: 'is-danger',
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.hero {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.ex-accounts {
  .table {
    tr.is-selected {
      background-color: var(--secondary-color)!important;
    }
    tr {
      cursor: pointer;
    }
  }
  .modal-card-title {
    margin: 0;
  }
  .heading {
    font-size: 2rem;
    Button {
      float: right;
    }
  }
  .datepicker {
    margin-top: 1rem;
    .has-text-primary {
      color: var(--primary-color) !important;
    }
    .select:not(.is-multiple):not(.is-loading)::after {
      border-color: var(--primary-color) !important;
    }
    .datepicker-table .datepicker-body {
      .datepicker-cell.is-selected {
        background-color: var(--primary-color) !important;
      }
      .datepicker-cell.is-today {
        border-color: var(--primary-color) !important;
      }
    }
    .select select:focus {
      border-color: var(--primary-color);
      box-shadow: none;
    }
  }
  .ex-tabs {
    ul {
      margin: 0 !important;
      margin-bottom: 0.5rem !important;
    }
    .tabs {
      padding: 0 !important;
      margin: 0 !important;
    }
    .tab-content {
      padding: 0 !important;
    }
  }
  .heading-add {
    font-size: 1.5rem;
    margin: 1.5rem 0rem;
  }
  .columns {
    padding: 0rem 2rem;
  }
  .dropdown-title {
    margin: .5rem;
    font-weight: 600;
  }
  .dropdown-button {
    margin: 1rem;
    font-size: 1.4rem;
  }
  .dropdown-list {
    font-size: 1.4rem;
  }
}
.account-title {
  display: inline;
  text-transform: uppercase;
  font-size: 3em;
}
.fat-text {
  font-family: "Noto Sans";
  font-weight: 900;
}
</style>
