<template>
  <section>
    <Navbar />
    <section class="account fixed-nav">
      <section class="hero is-small">
        <div class="responsive-pad">
          <h1 class="admin-title">{{ $t('account.title-thin') }} </h1>
          <h1 class="admin-title fat-text"> {{ $t('account.title-bold') }}</h1>
        </div>
      </section>
      <div class="responsive-pad">
        <!-- <button @click="create_te()">Create_TE</button>
        <button @click="temp_stats()">temp_TE_stats</button>
        <button @click="exportuserlinks()">exportuserlinks</button>
        <button @click="testImport()">testImport</button> -->
        <h2>{{ $t('account.admins') }}</h2>
        <b-table ref="table" v-if="showList" :key="admins.length"
        class="table"
        :data="admins"
        :paginated="isPaginated"
        :per-page="perPage"
        :current-page.sync="currentPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :default-sort-direction="defaultSortDirection"
        :sort-icon="sortIcon"
        :sort-icon-size="sortIconSize"
        default-sort="lastname"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page">

          <b-table-column field="firstname" :label="$t('profile.name')" sortable
          v-slot="props">
            {{ props.row.firstname }}
          </b-table-column>

          <b-table-column field="lastname" :label="$t('profile.surname')" sortable
          v-slot="props">
            {{ props.row.lastname }}
          </b-table-column>

          <b-table-column field="rights.acc" :label="$t('account.account-management')"
          v-slot="props">
            <span v-on:change="change_rights(props.row.id, props.row.rights)">
              <b-checkbox v-model="props.row.rights.acc"></b-checkbox>
            </span>
          </b-table-column>

          <b-table-column field="rights.group" :label="$t('account.group-management')"
          v-slot="props">
            <span v-on:change="change_rights(props.row.id, props.row.rights)">
              <b-checkbox v-model="props.row.rights.group"></b-checkbox>
            </span>
          </b-table-column>

          <b-table-column field="rights.training" :label="$t('account.training-management')"
          v-slot="props">
            <span v-on:change="change_rights(props.row.id, props.row.rights)">
              <b-checkbox v-model="props.row.rights.training"></b-checkbox>
            </span>
          </b-table-column>

          <b-table-column field="rights.assignment" :label="$t('account.assignment-management')"
          v-slot="props">
            <span v-on:change="change_rights(props.row.id, props.row.rights)">
              <b-checkbox v-model="props.row.rights.assignment"></b-checkbox>
            </span>
          </b-table-column>

          <b-table-column v-slot="props">
            <button v-if="props.row.id !== acc_owner_id" class="button is-small is-danger">
              <font-awesome-icon class="icon" :icon="['fa', 'trash']"
              @click="del(props.row)"/>
            </button>
            <span v-else>owner</span>
          </b-table-column>
        </b-table>
        <AddMember v-on:added="refreshTable" :useCase="'admin'" />
      </div>
    </section>
    <Footer />
  </section>
</template>

<script>
import AddMember from '../components/Clients/AddMember.vue';
import Navbar from '../../components/Common/Navbar.vue';
import Footer from '../../components/Common/Footer.vue';

export default {
  components: {
    Navbar,
    Footer,
    AddMember,
  },

  created() {
    this.$store.dispatch('Account/load');
  },

  data() {
    return {
      showList: true,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 10,
    };
  },

  computed: {
    user() {
      return this.$store.getters['User/user'];
    },
    client() {
      return this.$store.getters['User/client'];
    },
    admins() {
      return this.$store.getters['Account/admins'];
    },
    acc_owner_id() {
      return this.$store.getters['Account/acc_owner'];
    },
  },

  methods: {
    create_te() {
      this.$store.dispatch('Assignment/create_array');
    },
    temp_stats() {
      this.$store.dispatch('Assignment/temp_stats');
    },
    exportuserlinks() {
      this.$store.dispatch('User/exportuserlinks');
    },
    testImport() {
      this.$store.dispatch('User/testImport');
    },
    change_rights(userId1, rights1) {
      const payload = {
        userId: userId1,
        rights: rights1,
      };
      this.$store.dispatch('Account/change_rights', payload).then((response) => {
        if (response) {
          this.$buefy.toast.open({
            message: 'Admin hinzugefügt!',
            type: 'is-success',
          });
        } else {
          this.$buefy.toast.open({
            message: 'Es konnte kein Benutzer gefunden oder angelegt werden.',
            type: 'is-danger',
          });
        }
      });
    },
    refreshTable() {
      this.$store.dispatch('Account/load');
    },
    del(user) {
      this.$buefy.dialog.confirm({
        title: this.$t('account.del-admin'),
        message: this.$t('account.del-text'),
        cancelText: this.$t('common.cancel'),
        confirmText: this.$t('account.del-admin'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch('Account/del_user', user.id).then((response) => {
            if (response) {
              this.$buefy.toast.open({ message: this.$t('account.del-success'), type: 'is-success' });
            }
          });
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.account {

  h2 {
    font-size: 2rem;
  }

  .inline {
    display: inline-flex;
  }

  .hero {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .admin-title {
    display: inline;
    text-transform: uppercase;
    font-size: 3em;
  }

  .fat-text {
    font-family: "Roboto";
    font-weight: 900;
  }

  .background-color-client {
    background-color: var(--secondary-color);
    color: var(--secondary-color-text);
  }
}
</style>
